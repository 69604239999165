<script setup lang="ts">
import { Modal as AModal } from 'ant-design-vue'

const visible = useBoolean()

function refresh() {
  reloadNuxtApp()
}

// 加密key前
readOnlyValue(window, 'kfds3gf', toBase64('(**^&AX', true))

defineExpose({
  open: visible.setTrue,
  close: visible.setFalse,
})
</script>

<template>
  <AModal
    v-model:open="visible.is"
    :footer="null"
    :width="450"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
  >
    <div class="flex-center flex-col gap-30px">
      <i class="i-local:warning text-60px" />
      <p>
        {{ $t('3omdKaiyRBzdTnnO-tUeh') }}
      </p>

      <AButton class="comfirm-btn min-w-160px" data-test-id="7g65" @click="refresh">
        {{ $t('8aYOnRcMHjmXGXHxKeh2K') }}
      </AButton>
    </div>
  </AModal>
</template>
