import {useStorage, useUrlSearchParams} from '@vueuse/core';

export function useSourceInfo(){
  const sourceCache = useStorage(`SOURCE`,{time: 0 ,val: ''} )

  // 清空
  onMounted(()=>{
    if (sourceCache.value.time > 0){
      const current = (new Date()).getTime();
      if (current >= sourceCache.value.time){
        // 清空当前
        sourceCache.value.val = '';
        sourceCache.value.time = 0;
      }
    }
  })
  
  const _source = toRef(useUrlSearchParams('history').source)
  
  //  24小时后过期
  const stop = watch(_source, async (current)=>{
    if(!!current){
      sourceCache.value.time = (new Date()).getTime() + 24 * 60 * 60 * 1000;
      sourceCache.value.val = current.toString()
    }
    
  },{immediate:true})
  
  onUnmounted(()=> stop());
  
}
