import * as Sentry from '@sentry/vue'
import { withScope } from '@sentry/vue'
import type { Breadcrumb, CaptureContext, Primitive, User } from '@sentry/types'

export default defineNuxtPlugin({
  parallel: true,
  /*setup(nuxtApp) {
    if (typeof window === 'undefined') {
      return {
        provide: {
          sentrySetContext: (
            _name: string,
            _context: {
              [key: string]: any
            } | null,
          ) => {},
          sentrySetUser: (_user: User | null) => {},
          sentrySetTag: (_key: string, _value: Primitive) => {},
          sentryAddBreadcrumb: (_breadcrumb: Breadcrumb) => {},
          sentryCaptureException: (_exception: any, _captureContext?: CaptureContext) => {},
        },
      }
    }

    //
    Sentry.init({
      app: nuxtApp.vueApp,
      environment: import.meta.env.VITE_BUILD_MODE || 'development',
      dsn: import.meta.env.VITE_SENTRY_DSN,
      // debug: true,
      beforeSend(e, h) {
        const ua: string = e?.request?.headers['User-Agent']
        const match = ua.match(/Chrome\/(\d+)/)
        // 检查提取到的版本号是否大于 Chrome 70
        if (match && Number.parseInt(match[1]) <= 70)
          return null

        return e
      },
      beforeBreadcrumb(b, h) {
        return b
      },
      // attachStacktrace: true,
      integrations: [
        Sentry.breadcrumbsIntegration({
          console: false,
          sentry: false,
          dom: true,
          fetch: true,
          history: true,
          xhr: true,
        }),
        Sentry.dedupeIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: true,
        }),
      ],
      maxBreadcrumbs: 20,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      profilesSampleRate: 1.0,
      // release: '', // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/releases/
      // ignoreErrors: [], // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/inboundfilters/
      ignoreErrors: [
        /数据已存在/,
        'n.flatMap is not a function',
        /Hub.withScope/,
        'Request Timeout',
        '哎呀,您的手速太快啦...',
        'No error message',
        'Data already exists',
        'Failed to fetch',
        'Load failed',
        '已取消',
        '<unknown>',
        'The operation is insecure.', // 用户在Safari上开启了隐身模式导致，关闭即可
        'Network Error',
        'ReferenceError ?(<anonymous>)',
      ],
      // 测试忽略bind接口错误
      ignoreTransactions: [
        /POST \/v1\/(promote)\/(bind)/,
      ],
    })

    nuxtApp.vueApp.config.errorHandler = (err) => {
      withScope(() => {
        Sentry.captureException(err)
      })
    }

    nuxtApp.hook('app:error', (err) => {
      Sentry.captureException(err)
    })

    window.addEventListener('error', (event) => {
      // 检查是否是文件加载失败
      if (event.target && (event.target.tagName === 'SCRIPT' || event.target.tagName === 'LINK')) {
        const src = event.target.src || event.target.href
        Sentry.captureMessage(`文件加载失败: ${src}`, 'error')
      }
    })

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    }
  },*/
})

export function setWalletInfo(info: { name?: string; version?: string; address?: string }) {
  Sentry.setContext('钱包', info)
}

export function setUserInfo(info: { id?: number; name?: string }) {
  Sentry.setUser?.({
    id: info.id,
    username: info.name,
  })
}
