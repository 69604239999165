<script setup lang="ts">
import { antdUseFormFn, getGoogleVerifyFormRules } from '../config/formRules'
import type { GoogleVerifyModalProps } from '../type'

const $props = withDefaults(defineProps<GoogleVerifyModalProps>(), {
  modelValue: false,
  skipVerify: true,
})

const emits = defineEmits<{ (e: 'update:modelValue', data: boolean): void }>()

const visibleRef = ref(false)

const propsRef = ref<GoogleVerifyModalProps>()

watchEffect(() => {
  propsRef.value = $props
  visibleRef.value = $props.modelValue
})

const inputDomRef = ref<HTMLElement | null>(null)

watch(
  visibleRef,
  async (v) => {
    if (v) {
      await nextTick()
      inputDomRef.value?.focus?.()
    }
  },
)

const btnLoading = ref(false)

const formData = reactive({ security_code: '' })

const { validate, validateInfos, resetFields, clearValidate } = antdUseFormFn(
  formData,
  getGoogleVerifyFormRules(),
)

async function handleApi() {
  try {
    if (propsRef.value?.api) {
      const v = await propsRef.value?.api(formData.security_code)
      v && onClose()
    }
    else {
      onClose()
    }
  }
  finally {
    btnLoading.value = false
  }
}

function validateForm() {
  if (btnLoading.value)
    return
  validate().then(() => {
    btnLoading.value = true
    if (propsRef.value?.skipVerify) {
      handleApi()
      return
    }
    apis.apiAuthenticatorVerifyCode({ requestBody: formData })
      .then(handleApi)
      .catch(() => {
        btnLoading.value = false
      })
  })
}

function onClose() {
  resetFields?.()
  formData.security_code = ''
  clearValidate?.()
  visibleRef.value = false
  emits('update:modelValue', false)
  propsRef.value?.afterClose?.()
  propsRef.value = void 0
}

function onOpen(props?: Omit<GoogleVerifyModalProps, 'modelValue'> & { showSignRef?: Ref<boolean> }) {
  const setShowSignRef = (show = false) => {
    if (props?.showSignRef && isRef(props?.showSignRef))
      props.showSignRef.value = show
  }
  propsRef.value = void 0
  visibleRef.value = true
  emits('update:modelValue', true)
  setShowSignRef(true)
  propsRef.value = {
    ...$props,
    ...(props ?? {}),
    modelValue: true,
    afterClose() {
      props?.afterClose?.()
      setShowSignRef()
    },
  }
}

// 加密key后
readOnlyValue(window, 'g6ggva', toBase64('ASxa9s71!9)', true))

defineExpose({ open: onOpen, close: onClose })
</script>

<template>
  <ClientOnly>
    <AModal
      v-model:open="visibleRef"
      wrapClassName="ant-cover__basic-modal"
      :width="450"
      closable
      centered
      :maskClosable="false"
      :title="$t('prb0SjfJQfdwosDjCsxKn')"
      :footer="null"
      :zIndex="9999"
      @cancel="onClose"
    >
      <div class="w-full mt-4">
        <AForm @keypress.enter="validateForm">
          <AFormItem v-bind="validateInfos.security_code">
            <AInput
              ref="inputDomRef"
              v-model:value="formData.security_code"
              :placeholder="$t('vcE0NuDKoKofvBmx4LyQy')"
              :maxlength="6"
              allowClear
            >
              <template #prefix>
                <i class="i-ant-design:safety-outlined" />
              </template>
            </AInput>
          </AFormItem>
          <AButton class="comfirm-btn w-full" :loading="btnLoading" @click="validateForm">
            {{ okText || $t('tyv0SatMpCJkxg6oLqddv') }}
          </AButton>
        </AForm>
        <div class="mt-4">
          {{ $t('jk21xmAM1rFgR2Is9Fsh') }}
        </div>
      </div>
    </AModal>
  </ClientOnly>
</template>
