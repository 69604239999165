import { imtoken } from '~/utils/imtoken'

export function useSetSiteTitle() {
  const { config } = useAppState()
  const sideTilte = computed(() => {
    return config?.value?.base?.site_name
  })
  watch(sideTilte, (title) => {
    if (imtoken.isImToken())
      imtoken.setTitle(title)
  })
}
