import { createRequest } from './blog';

 const serviceName = 'blog.api';

 /**
 * 资讯分类
 */
export const apiNewsCategory = createRequest<
  undefined,
  { code: number; data: null | INewsMetaDetail[]; msg: string; request_id: string }
>("apiNewsCategory", () => ({ url: `/v1/news/category`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 资讯详情
 */
export const apiNewsDetail = createRequest<
  {
    news_id: number;
  },
  { code: number; data: null | INewsDetail; msg: string; request_id: string }
>("apiNewsDetail", ({ news_id }) => ({
  url: `/v1/news/detail`,
  method: "GET",
  params: {
    news_id,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 资讯列表
 */
export const apiNewsList = createRequest<
  {
    alias?: string;
    keyword?: string;
    mid?: number;
    page?: number;
    page_size?: number;
  },
  { code: number; data: null | INewsDetail[]; msg: string; pagination: IPagination; request_id: string }
>("apiNewsList", ({ mid, alias, keyword, page, page_size }) => ({
  url: `/v1/news/list`,
  method: "GET",
  params: {
    mid,
    alias,
    keyword,
    page,
    page_size,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 相关资讯列表
 */
export const apiNewsRelated = createRequest<
  {
    news_id: number;
    page_size?: number;
  },
  { code: number; data: null | INewsDetail[]; msg: string; request_id: string }
>("apiNewsRelated", ({ news_id, page_size }) => ({
  url: `/v1/news/related`,
  method: "GET",
  params: {
    news_id,
    page_size,
  },
  headers: { "Service-Name": serviceName },
}));

export interface IApiXiangQing {
  create_time: string;
  id: number;
  key: string;
  name: string;
  qps: number;
  quota: number;
  used: number;
  user_id: number;
}

export interface IBoChangFaQiJiaoYiJieGou {
  raw_data: {
    contract: { parameter?: { type_url: string; value: string }; type?: string }[];
    expiration: number;
    ref_block_bytes: string;
    ref_block_hash: string;
    timestamp: number;
  };
  raw_data_hex: string;
  signature: string[];
  txID: string;
  visible: boolean;
}

export interface IBoChangQianBaoJiBenXinXi {
  acquired_delegated_balance_for_bandwidth: number;
  acquired_delegated_balance_for_energy: number;
  acquired_delegated_for_bandwidth: number;
  acquired_delegated_for_energy: number;
  address: string;
  balance: number;
  can_delegated_bandwidth: number;
  can_delegated_bandwidth_max_size: number;
  can_delegated_energy: number;
  can_delegated_energy_max_size: number;
  delegated_balance_for_bandwidth: number;
  delegated_balance_for_energy: number;
  delegated_for_bandwidth: number;
  delegated_for_energy: number;
  energy_limit: number;
  energy_surplus: number;
  energy_used: number;
  free_net_limit: number;
  free_net_surplus: number;
  free_net_used: number;
  frozen_bandwidth: number;
  frozen_bandwidth_balance: number;
  frozen_energy: number;
  frozen_energy_balance: number;
  net_limit: number;
  net_surplus: number;
  net_used: number;
  total_assets: number;
}

export interface IChengGong {
  code: number;
  data: {};
  msg: string;
  request_id: string;
}

export interface IChongZhiXiangQing {
  amount: number;
  amount_decimals: number;
  create_time: number;
  decimals: number;
  from_address: string;
  order_no: string;
  pay_time: number;
  recharge_id: number;
  remark: string;
  status: number;
  symbol: string;
  to_address: string;
  txid: string;
}

export interface IDaJiaZaiZuDingDanXiangQing {
  create_time: number;
  freeze_time: number;
  frozen_tx_id: string;
  owner_address: string;
  receive_address: string;
  rent_duration: number;
  rent_expire_time: number;
  rent_time_second: number;
  resource_type: number;
  resource_value: number;
  status: number;
  unfreeze_time: number;
}

export interface IDaiLiDingDanYongJinXiangQing {
  action?: number;
  buy_settle_amount: number;
  buy_settle_ratio: number;
  buy_settled: number;
  buy_user_id: number;
  create_time: number;
  freeze_time: number;
  order_no: string;
  order_status: number;
  pay_amount: number;
  pay_time: number;
  refund_amount: number;
  sell_settle_amount: number;
  sell_settle_ratio: number;
  sell_settled: number;
  sell_user_id: number;
  service_amount: number;
  settle_status: number;
  settle_time: number;
  telegram_chat_id: number;
  telegram_chat_name: string;
}

export interface IDaiLiGaiLan {
  today_buy_commissions: number;
  today_buy_commissions_count: number;
  today_commissions: number;
  today_sell_commissions: number;
  today_sell_commissions_count: number;
  total_commissions: number;
  yesterday_buy_commissions: number;
  yesterday_buy_commissions_count: number;
  yesterday_commissions: number;
  yesterday_sell_commissions: number;
  yesterday_sell_commissions_count: number;
}

export interface IDaiLiShenQingXiangQing {
  apply_id: number;
  channel: string;
  contacts: string;
  cooperation_content: string;
  create_time: number;
  delete_time: number;
  handle_time: number;
  status: number;
}

export interface IDaiLiXiangQing {
  agent_id: number;
  allow_customize_price: number;
  announcement_cn: string;
  announcement_en: string;
  buy_increase_sun: number;
  buy_settle_ratio: number;
  domain: string;
  role: number;
  sell_settle_ratio: number;
  settle_address: string;
  show_last_trades: number;
  site_name: string;
  subdomain: string;
  telegram_bot_enable: number;
  telegram_bot_name: string;
  telegram_bot_token: string;
  telegram_bot_username: string;
  telegram_cs_username: string;
  template_code: string;
  total_buy_order_num: number;
  total_buy_settle_amount: number;
  total_sell_order_num: number;
  total_sell_settle_amount: number;
  total_settle_amount: number;
  user_id: number;
}

export interface IDingDanTiJiaoQingQiu {
  auto_to_market?: number;
  order_type: number;
  pay_address: string;
  pay_amount: number;
  pay_symbol: string;
  price_in_sun?: number;
  receive_address: string;
  rent_duration?: number;
  rent_time_second?: number;
  rent_time_unit: string;
  resource_type: number;
  resource_value: number;
}

export interface IDongJieDingDan {
  expire_time: number;
  from: string;
  frozen_balance: number;
  to: string;
  types: number;
}

export interface IDongJieDingDanXin {
  expireTime: number;
  frozenBalance: number;
  hash: string;
  ownerAddress: string;
  receiverAddress: string;
  resource: string;
  resourceValue: string;
  timestamp: number;
}

export interface IDuiHuanDingDanXiangQing {
  contract_address: string;
  create_time: number;
  exchange_amount: number;
  exchange_pay_status: number;
  exchange_rate: number;
  exchange_symbol: string;
  exchange_tx_id: string;
  finish_time: number;
  order_no: string;
  owner_address: string;
  pay_address: string;
  pay_amount: number;
  pay_status: number;
  pay_symbol: string;
  pay_time: number;
  pay_tx_id: string;
  pay_type: number;
  receive_address: string;
  status: number;
}

export interface IEstimate {
  have_token_energy_fee: number;
  have_token_energy_used: number;
  not_have_token_energy_fee: number;
  not_have_token_energy_used: number;
}

export interface IEstimateTransactionDetail {
  bandwidth_fee: number;
  bandwidth_used: number;
  energy_fee: number;
  energy_used: number;
}

export interface IJianKongDiZhiXiangQing {
  balance: number;
  chain: string;
  create_time: number;
  notify_url: string;
  receive_amount_limit: number;
  receive_in: number;
  receive_notify_url: number;
  receive_out: number;
  receive_telegram: number;
  receive_token: number;
  receive_usdt: number;
  remark: string;
  status: number;
  usdt_balance: number;
  wallet_address: string;
}

export interface IJianKongJiaoYiMingXi {
  amount: number;
  amount_decimals: number;
  chain: string;
  contract_address: string;
  create_time: number;
  decimals: number;
  fee: number;
  from_address: string;
  monitor_address: string;
  notify_telegram_status: number;
  notify_telegram_time: number;
  notify_url_finish_time: number;
  notify_url_status: number;
  notify_url_time: number;
  pay_time: number;
  remark: string;
  symbol: string;
  to_address: string;
  trx_balance: number;
  tx_hash: string;
  usdt_balance: number;
}

export interface IJieTiJiaGeXiangQing {
  extra_add_day: number;
  max: number;
  min: number;
  sun: number;
  sun_1d: number;
  sun_1h: number;
  sun_2d: number;
  sun_3h: number;
}

export interface ILadder {
  extra_add_day: number;
  max: number;
  min: number;
  sun: number;
  sun_1d: number;
  sun_1h: number;
  sun_2d: number;
  sun_3h: number;
}

export interface ILastTradeDetail {
  create_time: number;
  freeze_time: number;
  frozen_balance: number;
  frozen_tx_id: string;
  order_no: string;
  price_in_sun: number;
  receive_address: string;
  rent_time_second: number;
  resource_type: number;
  resource_value: number;
  settle_amount: number;
}

export interface IMaiJiaDingDanYueBaoBiaoXiangQing {
  bandwidth_value: number;
  energy_value: number;
  frozen_amount: number;
  modify_time: number;
  month: number;
  order_amount: number;
  order_bandwidth_num: number;
  order_energy_num: number;
  reward_amount: number;
  settle_amount: number;
  year: number;
}

export interface IMaiJiaGaiLanTongJi {
  last_week_power_reward: number;
  this_week_power_reward: number;
  today_freed_balance: number;
  today_freed_bandwidth: number;
  today_freed_energy: number;
  today_frozen_balance: number;
  today_order_bandwidth: number;
  today_order_bandwidth_num: number;
  today_order_energy: number;
  today_order_energy_num: number;
  today_power_reward: number;
  today_settle_amount: number;
  tomorrow_freed_balance: number;
  tomorrow_freed_bandwidth: number;
  tomorrow_freed_energy: number;
  total_order_waiting_settle_amount: number;
  total_profit: number;
  total_reward: number;
  total_settle_amount: number;
  yesterday_frozen_balance: number;
  yesterday_order_bandwidth: number;
  yesterday_order_bandwidth_num: number;
  yesterday_order_energy: number;
  yesterday_order_energy_num: number;
  yesterday_power_reward: number;
  yesterday_settle_amount: number;
}

export interface IMaiJiaXiangQing {
  allow_delegate: number;
  allow_freeze_v2: number;
  allow_undelegate: number;
  allow_unfreeze_v2: number;
  allow_vote_witness: number;
  allow_withdraw_balance: number;
  auto_freeze_v2: number;
  can_delegated_bandwidth: number;
  can_delegated_bandwidth_max_size: number;
  can_delegated_energy: number;
  can_delegated_energy_max_size: number;
  create_time: number;
  delegated_balance_for_bandwidth: number;
  delegated_balance_for_energy: number;
  exclusive_agency: number;
  freeze_time: number;
  frozen_bandwidth: number;
  frozen_bandwidth_balance: number;
  frozen_energy: number;
  frozen_energy_balance: number;
  permission_id: number;
  permission_name: string;
  power_limit: number;
  power_used: number;
  remark?: string;
  reward: number;
  reward_time: number;
  role: number;
  seller_id: number;
  settle_address: string;
  settle_expected_ratio: number;
  status: number;
  take_freezing_amount?: number;
  take_order: number;
  take_order_amount: number;
  take_order_bandwidth_min_sun: number;
  take_order_energy_min_sun: number;
  take_order_num: number;
  take_order_resource: number;
  take_settle_amount: number;
  to_address: string;
  total_assets: number;
  total_reward: number;
  trx_balance: number;
  unfreeze_time: number;
  update_run_time?: number;
  vote_annualized_rate: number;
  vote_time: number;
  wallet_address: string;
  withdraw_keep_amount: number;
  withdraw_type: number;
}

export interface INewsCommentDetail {
  comment_id: number;
  content: string;
  create_time: number;
  device_brand: string;
  device_type: string;
  last_reply?: null | INewsCommentDetail[];
  level: number;
  like_count: number;
  news_id: number;
  reply_count: number;
  status: number;
  user: null | {};
  user_id: number;
}

export interface INewsDetail {
  alias: string;
  allow_comment: number;
  category: null | INewsMetaDetail[];
  comment_count: number;
  content?: string;
  covers: string;
  create_time: number;
  is_ad: number;
  is_like: number;
  is_original: number;
  like_count: number;
  news_id: number;
  share_count: number;
  stick: number;
  summary: string;
  tag: null | INewsMetaDetail[];
  title: string;
  view_count: number;
}

export interface INewsMetaDetail {
  alias: string;
  count: number;
  mid: number;
  name: string;
}

export interface IOrderCronDetail {
  amount_limit: number;
  amount_used: number;
  count_limit: number;
  count_used: number;
  create_time: number;
  end_time: number;
  limit_type: number;
  order_resource_value: number;
  order_type: number;
  price_in_sun: number;
  remark: string;
  rent_time_second: number;
  resource_threshold: number;
  resource_type: number;
  start_time: number;
  status: number;
  wallet_address: string;
}

export interface IPagination {
  cursor?: string;
  has_more: boolean;
  page: number;
  page_size: number;
  total?: number;
}

export interface IShouQuanCaoZuoMingXiJiaoYiMingXi {
  amount: number;
  amount_decimals: number;
  contract_address: string;
  contract_type: number;
  create_time: number;
  decimals: number;
  finish_time: number;
  from_address: string;
  handle_time: number;
  order_no: string;
  pay_time: number;
  remark: string;
  seller?: IMaiJiaXiangQing;
  seller_id: number;
  to_address: string;
  transaction_id: number;
  txid: string;
  type: number;
}

export interface ISunConfig {
  service_amount: number;
  service_amount_limit: number;
  sun: number;
  sun_1d: number;
  sun_1h: number;
  sun_2d: number;
  sun_3h: number;
}

export interface ITiBiXiangQing {
  amount: number;
  audit_reason: string;
  audit_time: number;
  cash_status: number;
  create_time: number;
  fail_reason: string;
  finish_time: number;
  from_address: string;
  handle_status: number;
  handle_time: number;
  order_no: string;
  pay_time: number;
  remark: string;
  show_status: number;
  show_status_desc: string;
  symbol: string;
  to_address: string;
  tx_id: string;
}

export interface ITouPiaoLieBiao {
  address: string;
  annualizedRate: number;
  my_votes: number;
  name: string;
  real_time_votes: number;
}

export interface ITradeDetail {
  create_time: number;
  expire_time: number;
  frozen_balance: number;
  frozen_resource_value: number;
  frozen_tx_id: string;
  is_lock: number;
  is_split: number;
  lock_period: number;
  max_amount: number;
  max_freeze: number;
  max_payout: number;
  min_amount: number;
  min_freeze: number;
  min_payout: number;
  order_no: string;
  price_in_sun: number;
  receive_address: string;
  rent_duration: number;
  rent_time_second: number;
  rent_time_unit: string;
  resource_split_value: number;
  resource_type: number;
  resource_value: number;
  status: number;
}

export interface ITtPayOrderResp {
  expire_second: number;
  out_trade_no: string;
  payment_url: string;
  prepay_id: string;
}

export interface ITuiGuangMingXi {
  address: string;
  create_time: number;
  money: number;
}

export interface ITuiGuangYongHuMingXi {
  account: string;
  commission: number;
  email: string;
  is_seller: number;
  last_login_time: number;
  last_time: number;
  reg_time: number;
  status: number;
  telegram_first_name: string;
  telegram_last_name: string;
  telegram_username: string;
  trx_address: string;
  unique_id: string;
  user_id: number;
}

export interface IUsableRent {
  resource_val: number;
  today_energy: number;
  trx_money: number;
  yesterday_energy: number;
}

export interface IUserDetail {
  account: string;
  commission?: number;
  email: string;
  is_seller: number;
  last_login_time: number;
  last_time: number;
  reg_time: number;
  status: number;
  telegram_username: string;
  trx_address: string;
  unique_id: string;
  user_id?: number;
}

export interface IUserLiteDetail {
  lang: string;
  last_time: number;
  reg_time: number;
  telegram_first_name: string;
  telegram_last_name: string;
  trx_money: number;
  unique_id: string;
}

export interface IUserLiteDetailResp {
  lang: string;
  last_time: number;
  reg_time: number;
  telegram_first_name: string;
  telegram_last_name: string;
  this_month_order_amount: number;
  this_month_order_num: number;
  this_month_rent_energy: number;
  today_order_amount: number;
  today_order_num: number;
  today_rent_energy: number;
  trx_money: number;
  unique_id: string;
  yesterday_order_amount: number;
  yesterday_order_num: number;
  yesterday_rent_energy: number;
}

export interface IWeiTuoDingDanV2 {
  balance: number;
  expireTime: number;
  lockBalance: number;
  lockResourceValue: number;
  operationTime: number;
  ownerAddress: string;
  receiverAddress: string;
  resource: number;
  resourceValue: number;
}

export interface IWenZhang {
  article_id: number;
  content: string;
  content_en: string;
  create_time: number;
  image: string;
  image_en: string;
  is_popup: number;
  lang: string;
  modify_time: number;
  show_position: string;
  summary: string;
  summary_en: string;
  title: string;
  title_en: string;
  type: number;
  url: string;
  url_en: string;
}

export interface IYongHuBaoBiao {
  activity_amount: number;
  all_return_amount: number;
  bet_amount: number;
  bet_count: number;
  cash_amount: number;
  commission_amount: number;
  money: number;
  profit_amount: number;
  symbol: string;
  transfer_amount: number;
  win_amount: number;
}

export interface IYongHuXinXiBenRen {
  account: string;
  agent?: IDaiLiXiangQing;
  email: string;
  energy_balance?: number;
  invite_url: string;
  is_agent: number;
  is_seller: number;
  last_login_time: number;
  last_time: number;
  nickname?: string;
  reg_time: number;
  seller?: IMaiJiaXiangQing;
  status: number;
  telegram_bot_used: number;
  telegram_user_id: number;
  telegram_username: string;
  trx_address: string;
  trx_balance: number;
  trx_money: number;
  user_id: number;
  wallet?: IYongHuZhuanShuQianBao;
}

export interface IYongHuZhangBianXiangQing {
  amount: number;
  create_time: number;
  log_id: number;
  order_no: string;
  remark: string;
  tx_id: string;
  type: number;
}

export interface IYongHuZhuanShuQianBao {
  balance: number;
  usdt_balance: number;
  wallet_address: string;
}

export interface IZuYongDingDanXiangQing {
  agent?: IDaiLiDingDanYongJinXiangQing;
  cancel_tx_id?: string;
  create_time?: number;
  expire_time?: number;
  freeze_time: number;
  frozen_balance?: number;
  frozen_resource_value: number;
  frozen_status?: number;
  frozen_tx_id: string;
  is_lock: number;
  is_split: number;
  lock_period?: number;
  max_amount: number;
  max_freeze: number;
  max_payout: number;
  min_amount: number;
  min_freeze: number;
  min_payout: number;
  modify_time?: number;
  order_id?: number;
  order_no: string;
  order_num: number;
  order_type?: number;
  owner_address?: string;
  pay_action?: number;
  pay_address: string;
  pay_amount: number;
  pay_expiration?: number;
  pay_service_amount?: number;
  pay_status: number;
  pay_symbol: string;
  pay_time: number;
  pay_tx_id: string;
  price_in_sun: number;
  promote_commissions?: number;
  receive_address: string;
  refund_amount?: number;
  refund_time?: number;
  refund_tx_id?: string;
  rent_duration: number;
  rent_expire_time: number;
  rent_time_second: number;
  resource_split_value: number;
  resource_type: number;
  resource_value: number;
  status?: number;
  unfreeze_time: number;
  user_id?: number;
}
