import * as modals from '../global'
import { setupWatchLoginMode } from '~/pages-components/auth/hooks/watchLoginMode'

export { globalModal, GlobalModalsRegister }

const globalModal = {} as {
  [N in keyof typeof modals]?: InstanceType<typeof modals[N]>;
}
const GlobalModalsRegister = defineComponent({
  setup() {
    return () =>
      Object.entries(modals).map(([name, Modal]) => h(Modal, { ref: (m) => {
        (globalModal as any)[name] = m
        name === 'AuthModal' && m && setupWatchLoginMode()
      } }))
  },
})
