import * as apisMain from './specs/main.api'
import * as apisOpen from './specs/open.api'
import * as apisPay from './specs/pay.api'
import * as apisBlog from './specs/blog.api'

export { ApiError } from './helpers'

export { apis, apisOpen, apisPay, apisBlog }

const apis = apisMain
