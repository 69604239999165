import { Form } from 'ant-design-vue'
import type { Rule as AntdFormRule } from 'ant-design-vue/lib/form/interface'

function isEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
}

// 邮箱校验规则
export function getEmailRule() {
  const { t } = useI18n()
  return [
    {
      validator(_rule: AntdFormRule, value: string) {
        if (!value)
          return Promise.reject(t('bDkTufnfxYwhCnsJ19YOn'))
        if (!isEmail(value))
          return Promise.reject(t('hjBoftAim4uTlF3GEzFon'))
        return Promise.resolve()
      },
    },
  ]
}

// 密码校验规则
export function getPasswordRule() {
  const { t } = useI18n()
  return [
    {
      validator(_rule: AntdFormRule, value: string) {
        if (!value)
          return Promise.reject(t('xwmH1kUWs0aPnrI2tMPuJ'))
        // 密码大于 7位数
        if (value.length <= 7)
          return Promise.reject(t('aR9NaDbjc4X519H_7iOq'))
        return Promise.resolve()
      },
    },
  ]
}

// 确认密码校验规则
export function getPasswordConfirmRule(oldPassWard: Ref<string>) {
  const { t } = useI18n()
  return [
    {
      validator(_rule: AntdFormRule, value: string) {
        if (!value)
          return Promise.reject(t('mSe0FktUhcjOXrXiKczP'))
        if (value !== oldPassWard.value)
          return Promise.reject(t('olv18aVlpmaoL24eqdaPf'))
        return Promise.resolve()
      },
    },
  ]
}

/** 验证码校验规则 */
export function getCaptchaRule() {
  const { t } = useI18n()
  return [
    {
      validator(_rule: AntdFormRule, value: string) {
        if (!value)
          return Promise.reject(t('xAdIAbFau5NftOlJj2py'))
        if (value.length !== 5)
          return Promise.reject(t('5f9HaTYza3eR53gOav0Y0'))
        return Promise.resolve()
      },
    },
  ]
}

/** 登录表单配置规则 */
export function getLoginFormRule() {
  return reactive({
    account: getEmailRule(),
    password: getPasswordRule(),
    captcha: env.test ? [] : getCaptchaRule(),
  })
}

/** 注册表单配置规则 */
export function getRegisterFormRule(oldPassWard: Ref<string>) {
  return reactive({
    account: getEmailRule(),
    password: getPasswordRule(),
    password_confirm: getPasswordConfirmRule(oldPassWard),
    captcha: getCaptchaRule(),
  })
}

/** 忘记密码表单配置规则 */
export function getForgetPasswordFormRule() {
  return reactive({
    email: getEmailRule(),
    captcha: getCaptchaRule(),
  })
}

export function getGoogleVerifyFormRules() {
  const { t } = useI18n()
  return reactive({
    security_code: [
      {
        required: true,
        validator(_rule: AntdFormRule, value: string) {
          if (!value)
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(`${t('vcE0NuDKoKofvBmx4LyQy')}!`)
          return value.length === 6
            ? Promise.resolve()
            : Promise.reject(t('sdo5Qax05CRmGzZxkEqJy'))
        },
      },
    ],
  })
}

export const antdUseFormFn = Form.useForm
