export { createPromiser }

function createPromiser() {
  let resloveFn: (value: unknown) => void
  let rejectFn: (reason?: any) => void
  const promiser = new Promise((reslove, reject) => {
    resloveFn = reslove
    rejectFn = reject
  })

  return { promiser, reslove: resloveFn!, reject: rejectFn! }
}
