<script lang="ts" setup>
import { refAutoReset } from '@vueuse/core'

import { copyTextToClipboard } from '@/hooks/useCopyToClipboard'

const props = withDefaults(defineProps<{ content?: string | number; iconShow?: boolean }>(), {
  content: '',
  iconShow: true,
})

const isSuccess = refAutoReset<boolean | undefined>(undefined, 1000)
const msg = ref('')
const visible = computed(() => isSuccess.value !== undefined)
const color = computed(() => {
  if (isSuccess.value !== undefined)
    return isSuccess.value ? '#6BBF3F' : '#F35D55'

  return ''
})

async function copyHandle() {
  if (![undefined, null, ''].includes(props.content as any)) {
    isSuccess.value = copyTextToClipboard(String(props.content), {
      showMessage: false,
    })
    msg.value = isSuccess.value
      ? $t('UkodznCuNnWJZpOTkayKf')
      : $t('YkMKVLIaai6CjX6u6lYlg')
  }
}
</script>

<template>
  <span class="copy-btn-wrapper inline-block cursor-pointer">
    <ATooltip :open="visible" :mouseLeaveDelay="0">
      <template #title>{{ msg }}</template>
      <div class="btn flex items-center" @click="copyHandle" data-test-id="5njy">
        <slot name="content" />
        <slot />
        <slot v-if="iconShow" name="icon">
          <i
            v-if="isSuccess"
            class="i-ant-design:check-outlined icon ml-6px"
            :style="{ color }"
          />
          <i v-else class="icon i-local:copy-btn cursor-pointer ml-6px" />
        </slot>
      </div>
    </ATooltip>
  </span>
</template>
