import { default as index26MuFdvnB7Meta } from "D:/Project/vue/frontend_new/src/pages/agent/index.vue?macro=true";
import { default as detailffczXnNIIzMeta } from "D:/Project/vue/frontend_new/src/pages/announcement/detail.vue?macro=true";
import { default as indexpX0pseRmTbMeta } from "D:/Project/vue/frontend_new/src/pages/announcement/index.vue?macro=true";
import { default as indexkqNAyKWme3Meta } from "D:/Project/vue/frontend_new/src/pages/b2c/index.vue?macro=true";
import { default as indexsPpimbMvgsMeta } from "D:/Project/vue/frontend_new/src/pages/batch-renting/index.vue?macro=true";
import { default as _91blog_93_45_91blogId_93y1BNXQF9uqMeta } from "D:/Project/vue/frontend_new/src/pages/blog/[category]/[blog]-[blogId].vue?macro=true";
import { default as indexnCMDDwNVDUMeta } from "D:/Project/vue/frontend_new/src/pages/blog/[category]/index.vue?macro=true";
import { default as indexeMkV2Q6fEoMeta } from "D:/Project/vue/frontend_new/src/pages/blog/index.vue?macro=true";
import { default as searchs1tixAZDSPMeta } from "D:/Project/vue/frontend_new/src/pages/blog/search.vue?macro=true";
import { default as indexby3HeW9BzzMeta } from "D:/Project/vue/frontend_new/src/pages/bridgeswap/index.vue?macro=true";
import { default as indexSAK3O8CzZWMeta } from "D:/Project/vue/frontend_new/src/pages/c2c/index.vue?macro=true";
import { default as q1vro7qtrQAiMeta } from "D:/Project/vue/frontend_new/src/pages/help/q1.vue?macro=true";
import { default as q2hbxlmtz3ZqMeta } from "D:/Project/vue/frontend_new/src/pages/help/q2.vue?macro=true";
import { default as q3PTqW7BeMfSMeta } from "D:/Project/vue/frontend_new/src/pages/help/q3.vue?macro=true";
import { default as q4fW3rogh2doMeta } from "D:/Project/vue/frontend_new/src/pages/help/q4.vue?macro=true";
import { default as q5oQefA9wkQZMeta } from "D:/Project/vue/frontend_new/src/pages/help/q5.vue?macro=true";
import { default as q6Ekw8O4Xp0EMeta } from "D:/Project/vue/frontend_new/src/pages/help/q6.vue?macro=true";
import { default as q77pBe9wiUmIMeta } from "D:/Project/vue/frontend_new/src/pages/help/q7.vue?macro=true";
import { default as indexx0rzQaoNSsMeta } from "D:/Project/vue/frontend_new/src/pages/index/index.vue?macro=true";
import { default as indexVbQrRseeqQMeta } from "D:/Project/vue/frontend_new/src/pages/payment-result/index.vue?macro=true";
import { default as indexvuGDXfxLCsMeta } from "D:/Project/vue/frontend_new/src/pages/swap/index.vue?macro=true";
export default [
  {
    name: index26MuFdvnB7Meta?.name ?? "agent___en",
    path: index26MuFdvnB7Meta?.path ?? "/en/agent",
    meta: index26MuFdvnB7Meta || {},
    alias: index26MuFdvnB7Meta?.alias || [],
    redirect: index26MuFdvnB7Meta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/agent/index.vue").then(m => m.default || m)
  },
  {
    name: index26MuFdvnB7Meta?.name ?? "agent___zh___default",
    path: index26MuFdvnB7Meta?.path ?? "/agent",
    meta: index26MuFdvnB7Meta || {},
    alias: index26MuFdvnB7Meta?.alias || [],
    redirect: index26MuFdvnB7Meta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/agent/index.vue").then(m => m.default || m)
  },
  {
    name: index26MuFdvnB7Meta?.name ?? "agent___zh",
    path: index26MuFdvnB7Meta?.path ?? "/zh/agent",
    meta: index26MuFdvnB7Meta || {},
    alias: index26MuFdvnB7Meta?.alias || [],
    redirect: index26MuFdvnB7Meta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/agent/index.vue").then(m => m.default || m)
  },
  {
    name: detailffczXnNIIzMeta?.name ?? "announcement-detail___en",
    path: detailffczXnNIIzMeta?.path ?? "/en/announcement/detail",
    meta: detailffczXnNIIzMeta || {},
    alias: detailffczXnNIIzMeta?.alias || [],
    redirect: detailffczXnNIIzMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/announcement/detail.vue").then(m => m.default || m)
  },
  {
    name: detailffczXnNIIzMeta?.name ?? "announcement-detail___zh___default",
    path: detailffczXnNIIzMeta?.path ?? "/announcement/detail",
    meta: detailffczXnNIIzMeta || {},
    alias: detailffczXnNIIzMeta?.alias || [],
    redirect: detailffczXnNIIzMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/announcement/detail.vue").then(m => m.default || m)
  },
  {
    name: detailffczXnNIIzMeta?.name ?? "announcement-detail___zh",
    path: detailffczXnNIIzMeta?.path ?? "/zh/announcement/detail",
    meta: detailffczXnNIIzMeta || {},
    alias: detailffczXnNIIzMeta?.alias || [],
    redirect: detailffczXnNIIzMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/announcement/detail.vue").then(m => m.default || m)
  },
  {
    name: indexpX0pseRmTbMeta?.name ?? "announcement___en",
    path: indexpX0pseRmTbMeta?.path ?? "/en/announcement",
    meta: indexpX0pseRmTbMeta || {},
    alias: indexpX0pseRmTbMeta?.alias || [],
    redirect: indexpX0pseRmTbMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/announcement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpX0pseRmTbMeta?.name ?? "announcement___zh___default",
    path: indexpX0pseRmTbMeta?.path ?? "/announcement",
    meta: indexpX0pseRmTbMeta || {},
    alias: indexpX0pseRmTbMeta?.alias || [],
    redirect: indexpX0pseRmTbMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/announcement/index.vue").then(m => m.default || m)
  },
  {
    name: indexpX0pseRmTbMeta?.name ?? "announcement___zh",
    path: indexpX0pseRmTbMeta?.path ?? "/zh/announcement",
    meta: indexpX0pseRmTbMeta || {},
    alias: indexpX0pseRmTbMeta?.alias || [],
    redirect: indexpX0pseRmTbMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/announcement/index.vue").then(m => m.default || m)
  },
  {
    name: indexkqNAyKWme3Meta?.name ?? "b2c___en",
    path: indexkqNAyKWme3Meta?.path ?? "/en/b2c",
    meta: indexkqNAyKWme3Meta || {},
    alias: indexkqNAyKWme3Meta?.alias || [],
    redirect: indexkqNAyKWme3Meta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/b2c/index.vue").then(m => m.default || m)
  },
  {
    name: indexkqNAyKWme3Meta?.name ?? "b2c___zh___default",
    path: indexkqNAyKWme3Meta?.path ?? "/b2c",
    meta: indexkqNAyKWme3Meta || {},
    alias: indexkqNAyKWme3Meta?.alias || [],
    redirect: indexkqNAyKWme3Meta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/b2c/index.vue").then(m => m.default || m)
  },
  {
    name: indexkqNAyKWme3Meta?.name ?? "b2c___zh",
    path: indexkqNAyKWme3Meta?.path ?? "/zh/b2c",
    meta: indexkqNAyKWme3Meta || {},
    alias: indexkqNAyKWme3Meta?.alias || [],
    redirect: indexkqNAyKWme3Meta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/b2c/index.vue").then(m => m.default || m)
  },
  {
    name: indexsPpimbMvgsMeta?.name ?? "batch-renting___en",
    path: indexsPpimbMvgsMeta?.path ?? "/en/batch-renting",
    meta: indexsPpimbMvgsMeta || {},
    alias: indexsPpimbMvgsMeta?.alias || [],
    redirect: indexsPpimbMvgsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/batch-renting/index.vue").then(m => m.default || m)
  },
  {
    name: indexsPpimbMvgsMeta?.name ?? "batch-renting___zh___default",
    path: indexsPpimbMvgsMeta?.path ?? "/batch-renting",
    meta: indexsPpimbMvgsMeta || {},
    alias: indexsPpimbMvgsMeta?.alias || [],
    redirect: indexsPpimbMvgsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/batch-renting/index.vue").then(m => m.default || m)
  },
  {
    name: indexsPpimbMvgsMeta?.name ?? "batch-renting___zh",
    path: indexsPpimbMvgsMeta?.path ?? "/zh/batch-renting",
    meta: indexsPpimbMvgsMeta || {},
    alias: indexsPpimbMvgsMeta?.alias || [],
    redirect: indexsPpimbMvgsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/batch-renting/index.vue").then(m => m.default || m)
  },
  {
    name: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.name ?? "blog-category-blog-blogId___en",
    path: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.path ?? "/en/blog/:category()/:blog()-:blogId()",
    meta: _91blog_93_45_91blogId_93y1BNXQF9uqMeta || {},
    alias: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.alias || [],
    redirect: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/[category]/[blog]-[blogId].vue").then(m => m.default || m)
  },
  {
    name: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.name ?? "blog-category-blog-blogId___zh___default",
    path: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.path ?? "/blog/:category()/:blog()-:blogId()",
    meta: _91blog_93_45_91blogId_93y1BNXQF9uqMeta || {},
    alias: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.alias || [],
    redirect: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/[category]/[blog]-[blogId].vue").then(m => m.default || m)
  },
  {
    name: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.name ?? "blog-category-blog-blogId___zh",
    path: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.path ?? "/zh/blog/:category()/:blog()-:blogId()",
    meta: _91blog_93_45_91blogId_93y1BNXQF9uqMeta || {},
    alias: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.alias || [],
    redirect: _91blog_93_45_91blogId_93y1BNXQF9uqMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/[category]/[blog]-[blogId].vue").then(m => m.default || m)
  },
  {
    name: indexnCMDDwNVDUMeta?.name ?? "blog-category___en",
    path: indexnCMDDwNVDUMeta?.path ?? "/en/blog/:category()",
    meta: indexnCMDDwNVDUMeta || {},
    alias: indexnCMDDwNVDUMeta?.alias || [],
    redirect: indexnCMDDwNVDUMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnCMDDwNVDUMeta?.name ?? "blog-category___zh___default",
    path: indexnCMDDwNVDUMeta?.path ?? "/blog/:category()",
    meta: indexnCMDDwNVDUMeta || {},
    alias: indexnCMDDwNVDUMeta?.alias || [],
    redirect: indexnCMDDwNVDUMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnCMDDwNVDUMeta?.name ?? "blog-category___zh",
    path: indexnCMDDwNVDUMeta?.path ?? "/zh/blog/:category()",
    meta: indexnCMDDwNVDUMeta || {},
    alias: indexnCMDDwNVDUMeta?.alias || [],
    redirect: indexnCMDDwNVDUMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeMkV2Q6fEoMeta?.name ?? "blog___en",
    path: indexeMkV2Q6fEoMeta?.path ?? "/en/blog",
    meta: indexeMkV2Q6fEoMeta || {},
    alias: indexeMkV2Q6fEoMeta?.alias || [],
    redirect: indexeMkV2Q6fEoMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexeMkV2Q6fEoMeta?.name ?? "blog___zh___default",
    path: indexeMkV2Q6fEoMeta?.path ?? "/blog",
    meta: indexeMkV2Q6fEoMeta || {},
    alias: indexeMkV2Q6fEoMeta?.alias || [],
    redirect: indexeMkV2Q6fEoMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexeMkV2Q6fEoMeta?.name ?? "blog___zh",
    path: indexeMkV2Q6fEoMeta?.path ?? "/zh/blog",
    meta: indexeMkV2Q6fEoMeta || {},
    alias: indexeMkV2Q6fEoMeta?.alias || [],
    redirect: indexeMkV2Q6fEoMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: searchs1tixAZDSPMeta?.name ?? "blog-search___en",
    path: searchs1tixAZDSPMeta?.path ?? "/en/blog/search",
    meta: searchs1tixAZDSPMeta || {},
    alias: searchs1tixAZDSPMeta?.alias || [],
    redirect: searchs1tixAZDSPMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/search.vue").then(m => m.default || m)
  },
  {
    name: searchs1tixAZDSPMeta?.name ?? "blog-search___zh___default",
    path: searchs1tixAZDSPMeta?.path ?? "/blog/search",
    meta: searchs1tixAZDSPMeta || {},
    alias: searchs1tixAZDSPMeta?.alias || [],
    redirect: searchs1tixAZDSPMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/search.vue").then(m => m.default || m)
  },
  {
    name: searchs1tixAZDSPMeta?.name ?? "blog-search___zh",
    path: searchs1tixAZDSPMeta?.path ?? "/zh/blog/search",
    meta: searchs1tixAZDSPMeta || {},
    alias: searchs1tixAZDSPMeta?.alias || [],
    redirect: searchs1tixAZDSPMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/blog/search.vue").then(m => m.default || m)
  },
  {
    name: indexby3HeW9BzzMeta?.name ?? "bridgeswap___en",
    path: indexby3HeW9BzzMeta?.path ?? "/en/bridgeswap",
    meta: indexby3HeW9BzzMeta || {},
    alias: indexby3HeW9BzzMeta?.alias || [],
    redirect: indexby3HeW9BzzMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/bridgeswap/index.vue").then(m => m.default || m)
  },
  {
    name: indexby3HeW9BzzMeta?.name ?? "bridgeswap___zh___default",
    path: indexby3HeW9BzzMeta?.path ?? "/bridgeswap",
    meta: indexby3HeW9BzzMeta || {},
    alias: indexby3HeW9BzzMeta?.alias || [],
    redirect: indexby3HeW9BzzMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/bridgeswap/index.vue").then(m => m.default || m)
  },
  {
    name: indexby3HeW9BzzMeta?.name ?? "bridgeswap___zh",
    path: indexby3HeW9BzzMeta?.path ?? "/zh/bridgeswap",
    meta: indexby3HeW9BzzMeta || {},
    alias: indexby3HeW9BzzMeta?.alias || [],
    redirect: indexby3HeW9BzzMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/bridgeswap/index.vue").then(m => m.default || m)
  },
  {
    name: indexSAK3O8CzZWMeta?.name ?? "c2c___en",
    path: indexSAK3O8CzZWMeta?.path ?? "/en/c2c",
    meta: indexSAK3O8CzZWMeta || {},
    alias: indexSAK3O8CzZWMeta?.alias || [],
    redirect: indexSAK3O8CzZWMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/c2c/index.vue").then(m => m.default || m)
  },
  {
    name: indexSAK3O8CzZWMeta?.name ?? "c2c___zh___default",
    path: indexSAK3O8CzZWMeta?.path ?? "/c2c",
    meta: indexSAK3O8CzZWMeta || {},
    alias: indexSAK3O8CzZWMeta?.alias || [],
    redirect: indexSAK3O8CzZWMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/c2c/index.vue").then(m => m.default || m)
  },
  {
    name: indexSAK3O8CzZWMeta?.name ?? "c2c___zh",
    path: indexSAK3O8CzZWMeta?.path ?? "/zh/c2c",
    meta: indexSAK3O8CzZWMeta || {},
    alias: indexSAK3O8CzZWMeta?.alias || [],
    redirect: indexSAK3O8CzZWMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/c2c/index.vue").then(m => m.default || m)
  },
  {
    name: q1vro7qtrQAiMeta?.name ?? "help-q1___en",
    path: q1vro7qtrQAiMeta?.path ?? "/en/help/q1",
    meta: q1vro7qtrQAiMeta || {},
    alias: q1vro7qtrQAiMeta?.alias || [],
    redirect: q1vro7qtrQAiMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q1.vue").then(m => m.default || m)
  },
  {
    name: q1vro7qtrQAiMeta?.name ?? "help-q1___zh___default",
    path: q1vro7qtrQAiMeta?.path ?? "/help/q1",
    meta: q1vro7qtrQAiMeta || {},
    alias: q1vro7qtrQAiMeta?.alias || [],
    redirect: q1vro7qtrQAiMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q1.vue").then(m => m.default || m)
  },
  {
    name: q1vro7qtrQAiMeta?.name ?? "help-q1___zh",
    path: q1vro7qtrQAiMeta?.path ?? "/zh/help/q1",
    meta: q1vro7qtrQAiMeta || {},
    alias: q1vro7qtrQAiMeta?.alias || [],
    redirect: q1vro7qtrQAiMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q1.vue").then(m => m.default || m)
  },
  {
    name: q2hbxlmtz3ZqMeta?.name ?? "help-q2___en",
    path: q2hbxlmtz3ZqMeta?.path ?? "/en/help/q2",
    meta: q2hbxlmtz3ZqMeta || {},
    alias: q2hbxlmtz3ZqMeta?.alias || [],
    redirect: q2hbxlmtz3ZqMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q2.vue").then(m => m.default || m)
  },
  {
    name: q2hbxlmtz3ZqMeta?.name ?? "help-q2___zh___default",
    path: q2hbxlmtz3ZqMeta?.path ?? "/help/q2",
    meta: q2hbxlmtz3ZqMeta || {},
    alias: q2hbxlmtz3ZqMeta?.alias || [],
    redirect: q2hbxlmtz3ZqMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q2.vue").then(m => m.default || m)
  },
  {
    name: q2hbxlmtz3ZqMeta?.name ?? "help-q2___zh",
    path: q2hbxlmtz3ZqMeta?.path ?? "/zh/help/q2",
    meta: q2hbxlmtz3ZqMeta || {},
    alias: q2hbxlmtz3ZqMeta?.alias || [],
    redirect: q2hbxlmtz3ZqMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q2.vue").then(m => m.default || m)
  },
  {
    name: q3PTqW7BeMfSMeta?.name ?? "help-q3___en",
    path: q3PTqW7BeMfSMeta?.path ?? "/en/help/q3",
    meta: q3PTqW7BeMfSMeta || {},
    alias: q3PTqW7BeMfSMeta?.alias || [],
    redirect: q3PTqW7BeMfSMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q3.vue").then(m => m.default || m)
  },
  {
    name: q3PTqW7BeMfSMeta?.name ?? "help-q3___zh___default",
    path: q3PTqW7BeMfSMeta?.path ?? "/help/q3",
    meta: q3PTqW7BeMfSMeta || {},
    alias: q3PTqW7BeMfSMeta?.alias || [],
    redirect: q3PTqW7BeMfSMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q3.vue").then(m => m.default || m)
  },
  {
    name: q3PTqW7BeMfSMeta?.name ?? "help-q3___zh",
    path: q3PTqW7BeMfSMeta?.path ?? "/zh/help/q3",
    meta: q3PTqW7BeMfSMeta || {},
    alias: q3PTqW7BeMfSMeta?.alias || [],
    redirect: q3PTqW7BeMfSMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q3.vue").then(m => m.default || m)
  },
  {
    name: q4fW3rogh2doMeta?.name ?? "help-q4___en",
    path: q4fW3rogh2doMeta?.path ?? "/en/help/q4",
    meta: q4fW3rogh2doMeta || {},
    alias: q4fW3rogh2doMeta?.alias || [],
    redirect: q4fW3rogh2doMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q4.vue").then(m => m.default || m)
  },
  {
    name: q4fW3rogh2doMeta?.name ?? "help-q4___zh___default",
    path: q4fW3rogh2doMeta?.path ?? "/help/q4",
    meta: q4fW3rogh2doMeta || {},
    alias: q4fW3rogh2doMeta?.alias || [],
    redirect: q4fW3rogh2doMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q4.vue").then(m => m.default || m)
  },
  {
    name: q4fW3rogh2doMeta?.name ?? "help-q4___zh",
    path: q4fW3rogh2doMeta?.path ?? "/zh/help/q4",
    meta: q4fW3rogh2doMeta || {},
    alias: q4fW3rogh2doMeta?.alias || [],
    redirect: q4fW3rogh2doMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q4.vue").then(m => m.default || m)
  },
  {
    name: q5oQefA9wkQZMeta?.name ?? "help-q5___en",
    path: q5oQefA9wkQZMeta?.path ?? "/en/help/q5",
    meta: q5oQefA9wkQZMeta || {},
    alias: q5oQefA9wkQZMeta?.alias || [],
    redirect: q5oQefA9wkQZMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q5.vue").then(m => m.default || m)
  },
  {
    name: q5oQefA9wkQZMeta?.name ?? "help-q5___zh___default",
    path: q5oQefA9wkQZMeta?.path ?? "/help/q5",
    meta: q5oQefA9wkQZMeta || {},
    alias: q5oQefA9wkQZMeta?.alias || [],
    redirect: q5oQefA9wkQZMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q5.vue").then(m => m.default || m)
  },
  {
    name: q5oQefA9wkQZMeta?.name ?? "help-q5___zh",
    path: q5oQefA9wkQZMeta?.path ?? "/zh/help/q5",
    meta: q5oQefA9wkQZMeta || {},
    alias: q5oQefA9wkQZMeta?.alias || [],
    redirect: q5oQefA9wkQZMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q5.vue").then(m => m.default || m)
  },
  {
    name: q6Ekw8O4Xp0EMeta?.name ?? "help-q6___en",
    path: q6Ekw8O4Xp0EMeta?.path ?? "/en/help/q6",
    meta: q6Ekw8O4Xp0EMeta || {},
    alias: q6Ekw8O4Xp0EMeta?.alias || [],
    redirect: q6Ekw8O4Xp0EMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q6.vue").then(m => m.default || m)
  },
  {
    name: q6Ekw8O4Xp0EMeta?.name ?? "help-q6___zh___default",
    path: q6Ekw8O4Xp0EMeta?.path ?? "/help/q6",
    meta: q6Ekw8O4Xp0EMeta || {},
    alias: q6Ekw8O4Xp0EMeta?.alias || [],
    redirect: q6Ekw8O4Xp0EMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q6.vue").then(m => m.default || m)
  },
  {
    name: q6Ekw8O4Xp0EMeta?.name ?? "help-q6___zh",
    path: q6Ekw8O4Xp0EMeta?.path ?? "/zh/help/q6",
    meta: q6Ekw8O4Xp0EMeta || {},
    alias: q6Ekw8O4Xp0EMeta?.alias || [],
    redirect: q6Ekw8O4Xp0EMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q6.vue").then(m => m.default || m)
  },
  {
    name: q77pBe9wiUmIMeta?.name ?? "help-q7___en",
    path: q77pBe9wiUmIMeta?.path ?? "/en/help/q7",
    meta: q77pBe9wiUmIMeta || {},
    alias: q77pBe9wiUmIMeta?.alias || [],
    redirect: q77pBe9wiUmIMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q7.vue").then(m => m.default || m)
  },
  {
    name: q77pBe9wiUmIMeta?.name ?? "help-q7___zh___default",
    path: q77pBe9wiUmIMeta?.path ?? "/help/q7",
    meta: q77pBe9wiUmIMeta || {},
    alias: q77pBe9wiUmIMeta?.alias || [],
    redirect: q77pBe9wiUmIMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q7.vue").then(m => m.default || m)
  },
  {
    name: q77pBe9wiUmIMeta?.name ?? "help-q7___zh",
    path: q77pBe9wiUmIMeta?.path ?? "/zh/help/q7",
    meta: q77pBe9wiUmIMeta || {},
    alias: q77pBe9wiUmIMeta?.alias || [],
    redirect: q77pBe9wiUmIMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/help/q7.vue").then(m => m.default || m)
  },
  {
    name: indexx0rzQaoNSsMeta?.name ?? "index___en",
    path: indexx0rzQaoNSsMeta?.path ?? "/en",
    meta: indexx0rzQaoNSsMeta || {},
    alias: indexx0rzQaoNSsMeta?.alias || [],
    redirect: indexx0rzQaoNSsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexx0rzQaoNSsMeta?.name ?? "index___zh___default",
    path: indexx0rzQaoNSsMeta?.path ?? "/",
    meta: indexx0rzQaoNSsMeta || {},
    alias: indexx0rzQaoNSsMeta?.alias || [],
    redirect: indexx0rzQaoNSsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexx0rzQaoNSsMeta?.name ?? "index___zh",
    path: indexx0rzQaoNSsMeta?.path ?? "/zh",
    meta: indexx0rzQaoNSsMeta || {},
    alias: indexx0rzQaoNSsMeta?.alias || [],
    redirect: indexx0rzQaoNSsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexVbQrRseeqQMeta?.name ?? "payment-result___en",
    path: indexVbQrRseeqQMeta?.path ?? "/en/payment-result",
    meta: indexVbQrRseeqQMeta || {},
    alias: indexVbQrRseeqQMeta?.alias || [],
    redirect: indexVbQrRseeqQMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/payment-result/index.vue").then(m => m.default || m)
  },
  {
    name: indexVbQrRseeqQMeta?.name ?? "payment-result___zh___default",
    path: indexVbQrRseeqQMeta?.path ?? "/payment-result",
    meta: indexVbQrRseeqQMeta || {},
    alias: indexVbQrRseeqQMeta?.alias || [],
    redirect: indexVbQrRseeqQMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/payment-result/index.vue").then(m => m.default || m)
  },
  {
    name: indexVbQrRseeqQMeta?.name ?? "payment-result___zh",
    path: indexVbQrRseeqQMeta?.path ?? "/zh/payment-result",
    meta: indexVbQrRseeqQMeta || {},
    alias: indexVbQrRseeqQMeta?.alias || [],
    redirect: indexVbQrRseeqQMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/payment-result/index.vue").then(m => m.default || m)
  },
  {
    name: indexvuGDXfxLCsMeta?.name ?? "swap___en",
    path: indexvuGDXfxLCsMeta?.path ?? "/en/swap",
    meta: indexvuGDXfxLCsMeta || {},
    alias: indexvuGDXfxLCsMeta?.alias || [],
    redirect: indexvuGDXfxLCsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/swap/index.vue").then(m => m.default || m)
  },
  {
    name: indexvuGDXfxLCsMeta?.name ?? "swap___zh___default",
    path: indexvuGDXfxLCsMeta?.path ?? "/swap",
    meta: indexvuGDXfxLCsMeta || {},
    alias: indexvuGDXfxLCsMeta?.alias || [],
    redirect: indexvuGDXfxLCsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/swap/index.vue").then(m => m.default || m)
  },
  {
    name: indexvuGDXfxLCsMeta?.name ?? "swap___zh",
    path: indexvuGDXfxLCsMeta?.path ?? "/zh/swap",
    meta: indexvuGDXfxLCsMeta || {},
    alias: indexvuGDXfxLCsMeta?.alias || [],
    redirect: indexvuGDXfxLCsMeta?.redirect || undefined,
    component: () => import("D:/Project/vue/frontend_new/src/pages/swap/index.vue").then(m => m.default || m)
  }
]