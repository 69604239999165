import type { LoginSuccessCallbackType } from '../type'
import { useUserInfo } from '~/composables/app'

const { authLogin, authTronLogin, authTelegramLogin, authGoogleOAuthSignIn } = apis

/**
 * @description: 获取登录方法
 */
export function useLoginApiActions(callback?: LoginSuccessCallbackType) {
  const { t } = useI18n()
  const { userInfo, userInfoQuerier } = useUserInfo()

  async function afterLoginAction() {
    await userInfoQuerier.refetch()
    const user = userInfo.value
    user && aMessage.success(`${t('f6dJkcNH1Ez26bApJknu')}：${user.account}`)
    callback?.(user)
    return user ?? null
  }

  function generateLoginFn<T extends Api.Request>(fn: T) {
    return async (requestBody: Api.GetParam<T>['requestBody']) => {
      const res: any = await fn(
        { requestBody },
        { errorMessageMode: 'none', resultType: 'axios' },
      )
      if (res?.data?.code === 0)
        return await afterLoginAction()

      const errData = {
        code: ~~(res.data?.code ?? -1),
        message: res?.data?.msg ?? res?.data?.message ?? '',
        email: res?.data?.data?.email ?? res?.data?.data?.account ?? '',
      }

      return Promise.reject(errData)
    }
  }

  /** 邮箱登录 */
  const emailLogin = generateLoginFn(authLogin)

  /** 钱包一键登录 */
  const tronLogin = generateLoginFn(authTronLogin)

  /** Telegram一键登录 */
  const telegramLogin = generateLoginFn(authTelegramLogin)

  /** Google一键登录 */
  const googleLogin = generateLoginFn(authGoogleOAuthSignIn)

  return { emailLogin, tronLogin, telegramLogin, googleLogin }
}
