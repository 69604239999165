import revive_payload_client_Eyu3PtmybU from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.23_eslint@8.57.0_less@4.2.0_rollup@4.17.2_sass@1.64.1_stylelint@_sdgmwjqs726rjjhhh4b7q4xeoi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_e97VrC8WE0 from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.23_eslint@8.57.0_less@4.2.0_rollup@4.17.2_sass@1.64.1_stylelint@_sdgmwjqs726rjjhhh4b7q4xeoi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TinlWpFBQ7 from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.23_eslint@8.57.0_less@4.2.0_rollup@4.17.2_sass@1.64.1_stylelint@_sdgmwjqs726rjjhhh4b7q4xeoi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "D:/Project/vue/frontend_new/.nuxt/components.plugin.mjs";
import prefetch_client_CcbBoAUu5T from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.23_eslint@8.57.0_less@4.2.0_rollup@4.17.2_sass@1.64.1_stylelint@_sdgmwjqs726rjjhhh4b7q4xeoi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "D:/Project/vue/frontend_new/.nuxt/unocss.mjs";
import composition_FVCoypGlw2 from "D:/Project/vue/frontend_new/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.11_rollup@4.17.2_vue-router@4.3.0_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_IcccXvVMQ1 from "D:/Project/vue/frontend_new/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.11_rollup@4.17.2_vue-router@4.3.0_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_1aRyILCYtr from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.23_eslint@8.57.0_less@4.2.0_rollup@4.17.2_sass@1.64.1_stylelint@_sdgmwjqs726rjjhhh4b7q4xeoi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_fgMA0bV170 from "D:/Project/vue/frontend_new/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.23_eslint@8.57.0_less@4.2.0_rollup@4.17.2_sass@1.64.1_stylelint@_sdgmwjqs726rjjhhh4b7q4xeoi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import query_client_dJ5u6mIYKB from "D:/Project/vue/frontend_new/src/plugins/query-client.ts";
import sentry_client_KAXFuL2wum from "D:/Project/vue/frontend_new/src/plugins/sentry.client.ts";
import theme_jPQ6APFowV from "D:/Project/vue/frontend_new/src/plugins/theme.ts";
export default [
  revive_payload_client_Eyu3PtmybU,
  unhead_e97VrC8WE0,
  router_TinlWpFBQ7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CcbBoAUu5T,
  unocss_MzCDxu9LMj,
  composition_FVCoypGlw2,
  i18n_IcccXvVMQ1,
  chunk_reload_client_1aRyILCYtr,
  check_outdated_build_client_fgMA0bV170,
  query_client_dJ5u6mIYKB,
  sentry_client_KAXFuL2wum,
  theme_jPQ6APFowV
]