import { createGlobalState } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { apis } from '~/api'
import { hasToken } from '~/utils'
import { queryClient } from '~/query-client'

export { useUserInfo }

const useUserInfo = createGlobalState(() => {
  const userInfoQuerier = useQuery({
    queryKey: [apis.userInfo.id],
    enabled: hasToken(),
    refetchOnWindowFocus: false,
    retry: 2,
    queryFn: () => {
      return apis.userInfo(undefined, { errorMessageMode: 'none' })
    },
  })

  const userInfo = computed(() => userInfoQuerier.data.value)
  const isAgent = computed(() => userInfoQuerier.data.value?.is_agent)
  const uniqueId = computed(() => userInfoQuerier.data.value?.unique_id)
  const hasLogged = computed(() => !!(userInfoQuerier.data.value?.user_id && hasToken()))
  const trxMoney = computed(() => userInfoQuerier.data.value?.trx_money)
  const isLoading = computed(() => !!userInfoQuerier.isLoading.value)

  const clearInfo = () => {
    queryClient.setQueryData([apis.userInfo.id], () => null)
  }

  return {
    isAgent,
    uniqueId,
    userInfo,
    userInfoQuerier,
    hasLogged,
    trxMoney,
    clearInfo,
    isLoading,
  }
})
