import { useAppBreakpoints } from '@peng_kai/theme/vue/index'

const parser = value => value.replace(/[^0-9.]/g, '')
const bp = useAppBreakpoints()

export const inputMoney = {
  formatter(value, { userTyping, input }) {
    const turn = userTyping ? parser(input) : value

    return `${turn}`.replace(/\d+/, (n) => {
      // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
        return `${$1},`
      })
    })
  },
}

// 过滤地址
export const inputAddress = {
  formatter(newValue, { userTyping, oldValue }) {

  },
}
