<script lang="ts" setup>
import { createPromiser } from './helpers'
import { tron } from '~/utils/tron-v2'

defineOptions({
  inheritAttrs: false,
})
const emits = defineEmits<{
  (e: 'connect', promiser: Promise<unknown>): void
}>()

const { isConnected } = tronComp.useAccountCurrent()
const connectingModal = useBoolean()
const connectionStatus = ref(1) // 连接超时状态 0 连接中 1 异常 2 连接频繁
let promiser: ReturnType<typeof createPromiser>

async function connect() {
  promiser = createPromiser()
  emits('connect', promiser.promiser)
  connectingModal.setTrue()

  connectionStatus.value = 0

  setTimeout(() => {
    connectionStatus.value = 1
  }, 90000)

  // 开始连接 TronLink 钱包
  try {
    const connected = await tron.connect()

    if (connected) {
      // 轮询钱包是否已连接
      const timer = setInterval(() => {
        if (isConnected.value) {
          window.clearInterval(timer)
          connectingModal.setFalse()
          promiser.reslove(true)
        }
      }, 200)
    }
  }
  catch (e) {
    if (e instanceof Error) {
      if(e.message.includes('CONNECT_REQUEST_FREQUENTLY')){
        aMessage.warning($t('t06NhkhihYu2CPl055387'))
        connectionStatus.value = 2
        setTimeout(() => {
          close(e)
        }, 10000);
      }
      if(e.message.includes('CONNECT_WALLET_ERROR')){
        connectionStatus.value = 1
        close(e)
      }
      if (e.message.includes('CONNECT_WALLET_REJECTED')){
        aMessage.warning($t('8PlhVygj1A0JvchO434qC'))
        close(e)
      }

    }
  }
}

function onRefresh() {
  window.location.reload()
}

function close(reason?: any) {
  connectingModal.setFalse()
  return promiser.reject(reason)
}
</script>

<template>
  <div class="main">
    <div class="item tron" @click="connect()" data-test-id="hfem">
      <i class="icon i-local:tronlink-s2" />
      <span class="">TronLink</span>
    </div>
  </div>
  <AModal
    wrapClassName="ant-cover__basic-modal"
    :open="connectingModal.is"
    :title="$t('zL6Oc0uedTZrfB0ql9xWj')"
    :footer="null"
    :width="540"
    :zIndex="2001"
    @cancel="close()"
    destroyOnClose
    :maskClosable="false"
  >
    <div class="connecting text-center">
      <template v-if="!tron.supported()">
        <i class="icon i-local:warning mt-24px" />
        <p class="title">{{ $t('Scs1BtJFiQfC2n2eQ2AiC') }}</p>
        <p class="sub-title">{{ $t('7gVmuAgRbY3q3O2eMAyU') }}</p>
      </template>

      <template v-if="connectionStatus == 0">
        <i class="icon i-local:loding animate-spin" />
        <p class="title">{{ $t('dmDO4qpxLqsJOzhU1teK8') }}</p>
        <p class="sub-title">{{ $t('O2Zj6U5svVIpQj2tUMSmV') }}</p>
      </template>

      <template v-if="connectionStatus == 1">
        <i class="icon i-local:warning" />
        <p class="title">{{ $t('HX6rE1Ogq8SLPMApNvdEp') }}</p>
        <p class="sub-title">{{ $t('qKGvQDSgkL86jRLTDvyID') }}</p>
      </template>

      <template v-if="connectionStatus == 2">
        <i class="icon i-ant-design:field-time-outlined" />
        <p class="title">{{ $t('t06NhkhihYu2CPl0IIIRR') }}</p>
        <p class="sub-title">{{ $t('t06NhkhihYu2CPl0IIIoo') }}</p>
      </template>

      <div class="bg-bg-info p-30px rounded-lg lt-mobile:py-10px lt-mobile:px-5px">
        <p class="text-text">{{ $t('qMPUnHayPIE4547SQfQYF') }} <a @click="onRefresh" data-test-id="pith">{{ $t('MbTztQaCSAvsfjCCmOJXY') }}</a></p>
        <p class="text-text">
          {{ $t('cqB1YavZSyoEaIKlGBTrt') }}
          <a target="_blank" href="https://www.tronlink.org/dlDetails/" data-test-id="aKjC">{{ $t('29a5tvaGc-ZY_qwKhIR6n') }}</a>
        </p>
      </div>
    </div>
  </AModal>
</template>

<style scoped lang="scss">
.main {
  --uno: 'px-30px mb-60px';

  .item {
    --uno: 'rounded-lg bg-bg-info px-30px py-15px text-left flex justify-start items-center text-18px text-text cursor-pointer transition duration-700 ease-in-out';
    --uno: 'hover:scale-105';

    .icon {
      --uno: 'text-40px mr-20px';
    }
  }
}

.connecting{
  .icon{
    --uno: "text-70px mb-37px ";
  }

  .title{
    --uno: "text-24px text-text";
  }

  .sub-title{
    --uno: "text-md mt-13px mb-30px text-primary";
  }
}
</style>
