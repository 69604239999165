<script lang="ts" setup>
import TronLink from './TronLink.vue'
import { createPromiser } from './helpers'
import { imtoken } from '~/utils/imtoken'

const selectorModal = useBoolean()
const { isConnected, account } = tronComp.useAccountCurrent()
const linkStatus = useBoolean(true)

let promiser: ReturnType<typeof createPromiser>

const connect = declareComponentOn(
  TronLink,
  'connect',
  async (methodPromiser) => {
    try {
      const res = await methodPromiser
      selectorModal.setFalse()
      promiser.reslove(res)
    }
    catch (err) {
      promiser.reject(err)
    }
  },
)

/**
 * 用于根据钱吧判断使用什么连接方式
 */
function currentWallet() {
  if (imtoken.isImToken())
    imtoken.showAccountSwitch()
  else
    tronlinkMode()
}

// tron
function tronlinkMode() {
  selectorModal.setTrue()
}

async function open() {
  promiser = createPromiser()
  currentWallet()
  return promiser.promiser
}
function close(reason?: any) {
  selectorModal.setFalse()
  return promiser.reject(reason)
}

defineExpose({ open, close })
</script>

<template>
  <AModal
    wrapClassName="ant-cover__basic-modal"
    :title="$t('wuWqn9c4GPKjbVAkKcBVu')"
    :open="selectorModal.is"
    :footer="false"
    :width="540"
    :zIndex="2000"
    @cancel="close"
  >
    <div class="text-left choice px-40px lt-mobile:px-10px">
      <p class="text-text text-xl my-30px text-center">
        {{ $t('Le4ODqDs5QTWLM2qUb6zp') }}
      </p>

      <TronLink @connect="connect" />

      <div class="prompt">
        {{ $t('05O6reqR5TyhdvH0thuBq') }}<a
          target="_blank"
          href="https://www.tronlink.org/dlDetails/"
          data-test-id="yZr2"
        >{{ $t('7o2umKh13QF-uUIzjptzu') }}</a>
      </div>
    </div>
  </AModal>
</template>

<style lang="scss" scoped></style>
