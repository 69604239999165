<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    force?: boolean
    translate: Function
    onLater: Function
    onReload: Function
  }>(),
  {
    force: false,
  },
)
</script>

<template>
  <div class="notification-wrap">
    <img class="rocket" src="@/assets/imgs/rocket.svg">
    <h2 class="title">{{ props.translate('oeGWimojPblVXuLbaCi0') }}</h2>
    <p class="msg">{{ props.translate('y8Z6Cme6GrMtj2Dng12L') }}</p>
    <div class="btns">
      <div v-if="!props.force" class="btn later" @click="props.onLater()" data-test-id="4w6y">{{ props.translate('1EWj_19XmStmMIxzVcxpn') }}</div>
      <div class="btn reload" @click="props.onReload()" data-test-id="p3bn">{{ props.translate('8aYOnRcMHjmXGXHxKeh2K') }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(.update-app-notification.update-app-notification .ant-notification-notice-close) {
  display: none;
}

.notification-wrap {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: auto 1fr;
  margin-bottom: -15px;

  .rocket {
    grid-row: 1 / 4;
    margin-top: 10px;
    margin-right: 20px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 20px;
    color: #fff;
  }

  .msg {
    font-size: 14px;
    color: #c8d0df;
  }

  .btns {
    display: flex;
    margin-top: 20px;
    color: #fff;

    .btn {
      flex: 1;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;

      + .btn {
        margin-left: 15px;
      }

      &.later {
        background-color: #6f8eca;
      }

      &.reload {
        background-color: #266ef1;
      }
    }
  }
}
</style>
