import pkg from '../../package.json'
import fp from '~/composables/useFingerprint'
import {useStorage} from '@vueuse/core';

export async function getReqHeaders() {
  const config:{[key:string] : any} = {
    'lang': unref(useNuxtApp()?.$i18n.locale) === 'zh' ? 'zh-CN' : 'en-US',
    'version': pkg.version,
    'Accept-Date': currentTimeZone(),
    'Device': await fp.getDeviceInfo()
  }
  const sourceCache = useStorage(`SOURCE`,{val:''})
  if (!!sourceCache)
    config.source = sourceCache.value.val
  
  return config
}
