export { isTimeout, _ApiCode as ApiCode, _ApiError as ApiError }

enum _ApiCode {
  NORMAL = 0, // 正常
  UNKNOWN = -1, // 未知错误
  TIMEOUT = -2, // 请求超时
}

function isTimeout(error: any) {
  return error?.message?.toLowerCase().includes('timeout')
}

function useInterceptor<Obj extends { use: (...args: any) => any }, Args extends Obj>(obj: Obj) {}

// 命名原因：避免由于自动导入造成的命名冲突
class _ApiError<TResp = any> extends Error {
  public static is<ErrorResp = any>(error: any): error is _ApiError<ErrorResp> {
    return !!error?.isApiError
  }

  /**
   * 将HTTP status和Api code统一到一个code里
   */
  public code: number
  public msg: string
  public data: TResp
  public isApiError = true

  public constructor(info: { code: number; msg: string; data: TResp }) {
    super(info.msg)
    this.code = info.code
    this.msg = info.msg
    this.data = info.data
  }
}
