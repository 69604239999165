import { createRequest } from './pay'

 const serviceName = 'pay.api';

 /**
 * 设置展示
 */
export const confList = createRequest<
  undefined,
  { code: number; data: { telegram_customer_id: string }; msg: string; request_id: string }
>("confList", () => ({ url: `/conf/config`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 设置更新
 */
export const confUpdate = createRequest<
  {
    requestBody?: { telegram_customer_id: string };
  },
  {
    code: number;
    data: {
      cash_auto: number;
      cash_binance_address: string;
      cash_ethereum_address: string;
      cash_tron_address: string;
      telegram_customer_id: string;
    };
    msg: string;
    request_id: string;
  }
>("confUpdate", ({ requestBody }) => ({
  url: `/conf/update`,
  method: "POST",
  data: requestBody,
  headers: { "Content-Type": "application/json", "Service-Name": serviceName },
}));

/**
 * 获取AWS临时授权
 */
export const fileAwsToken = createRequest<
  undefined,
  {
    code: number;
    data: {
      Credentials: { AccessKeyId: string; Expiration: string; SecretAccessKey: string; SessionToken: string };
      ResultMetadata: {};
    };
    msg: string;
    request_id: string;
  }
>("fileAwsToken", () => ({ url: `/file/aws_token`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 握手协议
 */
export const initHandshake = createRequest<
  undefined,
  {
    code: number;
    data: {
      app_name: string;
      app_version: string;
      file_bucket: string;
      file_domain: string;
      file_region: string;
      session_id: string;
      telegram_business_customer_id: string;
      telegram_channel_id: string;
      telegram_customer_id: string;
    };
    msg: string;
    request_id: string;
  }
>("initHandshake", () => ({ url: `/init/handshake`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 是否授权足够token
 */
export const swapAllownced = createRequest<
  {
    order_id: string;
  },
  { code: number; data: { amount: string; decimals: number }; msg: string; request_id: string }
>("swapAllownced", ({ order_id }) => ({
  url: `/swap/allownced`,
  method: "GET",
  params: {
    order_id,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 创建兑换订单
 */
export const swapCreate = createRequest<
  {
    requestBody?: {
      from_address?: string;
      from_token_address: string;
      from_token_amount: string;
      from_token_chain: string;
      is_platform_approve: number;
      to_address: string;
      to_token_address: string;
      to_token_chain: string;
    };
  },
  { code: number; data: ISwapDetail; msg: string; request_id: string }
>("swapCreate", ({ requestBody }) => ({
  url: `/swap/create`,
  method: "POST",
  data: requestBody,
  headers: { "Content-Type": "application/json", "Service-Name": serviceName },
}));

/**
 * 获取触发跨链桥合约的参数
 */
export const swapGetSignTx = createRequest<
  {
    order_id: string;
  },
  { code: number; data?: ISginTx | ISolanaTx; msg: string; request_id: string }
>("swapGetSignTx", ({ order_id }) => ({
  url: `/swap/sign_tx`,
  method: "GET",
  params: {
    order_id,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 获取即时汇率
 */
export const swapInstantRate = createRequest<
  {
    symbol: string;
  },
  {}
>("swapInstantRate", ({ symbol }) => ({
  url: `/swap/instant_rate`,
  method: "GET",
  params: {
    symbol,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 询价
 */
export const swapQute = createRequest<
  {
    fromTokenAddress: string;
    fromTokenAmount: string;
    fromTokenChain: string;
    is_platform_approve: number;
    toTokenAddress: string;
    toTokenChain: string;
    userAddr?: string;
  },
  { code: number; data: IQuote; msg: string; request_id: string }
>(
  "swapQute",
  ({
    fromTokenAddress,
    toTokenAddress,
    fromTokenAmount,
    fromTokenChain,
    toTokenChain,
    userAddr,
    is_platform_approve,
  }) => ({
    url: `/swap/quote`,
    method: "GET",
    params: {
      fromTokenAddress,
      toTokenAddress,
      fromTokenAmount,
      fromTokenChain,
      toTokenChain,
      userAddr,
      is_platform_approve,
    },
    headers: { "Service-Name": serviceName },
  }),
);

/**
 * 币种列表
 */
export const swapTokenList = createRequest<
  undefined,
  { code: number; data: (ITokenDetail | null)[]; msg: string; request_id: string }
>("swapTokenList", () => ({ url: `/swap/token_list`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 兑换详情
 */
export const swapTransDataDetail = createRequest<
  {
    order_id: string;
  },
  { code: number; data: ISwapDetail | null; msg: string; request_id: string }
>("swapTransDataDetail", ({ order_id }) => ({
  url: `/swap/trans_data_detail`,
  method: "GET",
  params: {
    order_id,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 兑换订单查询
 */
export const swapTransDataList = createRequest<
  {
    from_address: string;
    page: number;
    page_size: number;
  },
  { code: number; data?: ISwapDetail[]; msg: string; request_id: string }
>("swapTransDataList", ({ page, page_size, from_address }) => ({
  url: `/swap/trans_data_list`,
  method: "GET",
  params: {
    page,
    page_size,
    from_address,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 转账地址
 */
export const swapTransferAddress = createRequest<
  undefined,
  { code: number; data: ITransferAddress[]; msg: string; request_id: string }
>("swapTransferAddress", () => ({
  url: `/swap/transfer_address`,
  method: "GET",
  headers: { "Service-Name": serviceName },
}));

/**
 * 触发合约后更新订单信息
 */
export const updateHash = createRequest<
  {
    requestBody?: { hash: string; order_id: string };
  },
  { code: number; data: null; msg: string; request_id: string }
>("updateHash", ({ requestBody }) => ({
  url: `/swap/update_hash`,
  method: "POST",
  data: requestBody,
  headers: { "Content-Type": "application/json", "Service-Name": serviceName },
}));

export interface IChengGong {
  code: number;
  data: {};
  msg: string;
  request_id: string;
}

export interface IPagination {
  cursor?: string;
  has_more: boolean;
  page: number;
  page_size: number;
  total?: number;
}

export interface IQuote {
  amountOutMin: string;
  chainFee: string;
  contractAddress: string;
  depositMax: string;
  depositMin: string;
  dex: string;
  estimatedTime: number;
  fee: number;
  feeToken: string;
  fromTokenAmount: string;
  fromTokenDecimal: number;
  logoUrl: string;
  path: string[];
  toTokenAmount: string;
  toTokenDecimal: number;
}

export interface ISginTx {
  data: string;
  fromAddress: string;
  functionName: string;
  options: { callValue?: string; feeLimit: number };
  parameter: { type: string; value: string }[];
  to: string;
  tronRouterAddrees: string;
  tronRouterAddress: string;
  value: string;
}

export interface ISolanaTx {
  signer: string;
  tx: { data: string; keys: { isSigner: boolean; isWritable: boolean; pubkey: string }[]; programId: string }[];
}

export interface ISwapDetail {
  agent_id: number;
  approve_address: string;
  arrival_time: number;
  bridgers_order_id: string;
  chainFee: string;
  contract_address: string;
  create_time: number;
  deposit_hash_explore: string;
  deposit_max: string;
  deposit_min: string;
  dex: string;
  equipment_no: string;
  estimated_time: number;
  fee: number;
  fee_token: string;
  from_address: string;
  from_token_address: string;
  from_token_amount: string;
  from_token_chain: string;
  from_token_decimal: number;
  from_token_symbol: string;
  from_token_symbol_show: string;
  handle_status: number;
  id: number;
  is_approve: number;
  is_platform_approve: number;
  locale: string;
  merchant_id: number;
  order_id: string;
  platform_source: string;
  profit: string;
  receive_hash_explore: string;
  refund_coin_amount: number;
  refund_hash: string;
  refund_hash_explore: string;
  refund_reason: string;
  reserve_address: string;
  source_flag: string;
  status: string;
  swap_hash: string;
  to_address: string;
  to_hash: string;
  to_token_address: string;
  to_token_amount: string;
  to_token_chain: string;
  to_token_decimal: number;
  to_token_symbol: string;
  to_token_symbol_show: string;
  transfer_approve_address_amount: string;
  transfer_hash: string;
  transfer_hash_explore: string;
  trigger_contract_amount: string;
  trigger_contract_fee: string;
}

export interface ISwapList {
  createTime: string;
  depositHashExplore: string;
  dexName: string;
  equipmentNo: string;
  estimatedTime: number;
  fromAddress: string;
  fromAmount: string;
  fromChain: string;
  fromCoinCode: string;
  fromDecimals: string;
  fromGas: null;
  fromTokenAddress: string;
  fromTokenAmount: string;
  hash: string;
  id: number;
  orderId: string;
  platformSource: string;
  receiveHashExplore: string;
  refundCoinAmt: string;
  refundHash: string;
  refundHashExplore: string;
  refundReason: string;
  slippage: string;
  source: string;
  status: string;
  toAddress: string;
  toAmount: string;
  toChain: string;
  toCoinCode: string;
  toDecimals: string;
  toGas: null;
  toHash: string;
  toTokenAddress: string;
  toTokenAmount: string;
}

export interface ITokenDetail {
  address: string;
  chain: string;
  chainId: string;
  decimals: number;
  isCrossEnable: number;
  logoURI: string;
  name: string;
  symbol: string;
  symbol_show: string;
  withdrawGas: number;
}

export interface ITransferAddress {
  address: string;
  approve_to_address: string;
  chain: string;
  chain_id: number;
  create_time: number;
  json_rpc: string;
}
