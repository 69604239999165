<script setup lang="ts">
import lottie, { type AnimationItem } from 'lottie-web'
import type { AuthFormType, AuthModalProps, OpenAuthModalFnProps, TgUserData } from '../type'
import LoginForm from './LoginForm.vue'
import RegisterForm from './RegisterForm.vue'
import ForgetPasswordForm from './ForgetPasswordForm.vue'
import type { IYongHuXinXiBenRen } from '~/api/specs/main.api'
import animationData from '~/assets/imgs/json/pc-login-bg.json'
import { useUserInfo } from '~/composables/app'

const $props = withDefaults(defineProps<AuthModalProps>(), {
  modelValue: false,
  formType: 'login',
})

const emits = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

const visibleRef = ref(false)
const showFormTypeRef = ref<AuthFormType>('login')

const propsRef = ref<AuthModalProps | undefined>()

watchEffect(() => {
  propsRef.value = $props
  visibleRef.value = $props.modelValue
  showFormTypeRef.value = $props.formType
})

function changePages(type: AuthFormType) {
  showFormTypeRef.value = type
}

const animation = ref<AnimationItem | null>(null)

function setupAnimation() {
  const container = document.querySelector('.lottie-container')
  if (container) {
    animation.value?.destroy?.()
    animation.value = lottie.loadAnimation({
      container,
      animationData,
    })
    animation.value?.play?.()
  }
}

watch(visibleRef, (v) => {
  v && setTimeout(setupAnimation)
}, { immediate: true })

function handleLoginSuccess(user?: IYongHuXinXiBenRen) {
  propsRef.value?.loginSuccessCallback?.(user)
  onClose()
}

function onClose() {
  animation.value?.stop?.()
  animation.value?.destroy?.()
  animation.value = null
  visibleRef.value = false
  emits('update:modelValue', false)
  propsRef.value?.cancelCallback?.()
  propsRef.value = void 0
  showFormTypeRef.value = 'login'
}

const commonFormConfig = { backLoginFn: () => showFormTypeRef.value = 'login' }

const loginFormDomRef = ref()

function generateOpenFn<T extends Omit<AuthModalProps, 'modelValue'>>(type: AuthFormType | 'open') {
  return (props?: T) => {
    propsRef.value = void 0
    visibleRef.value = true
    emits('update:modelValue', true)
    propsRef.value = { ...$props, ...(props ?? {}), modelValue: true }
    if (type !== 'open')
      showFormTypeRef.value = type
    else
      props?.formType && (showFormTypeRef.value = props.formType)
  }
}

function generateToLoginFn<T>(type: 'telegram' | 'google') {
  return (params: T) => {
    if (useUserInfo().hasLogged.value || visibleRef.value)
      return
    visibleRef.value = true
    showFormTypeRef.value = 'login'
    setTimeout(() => {
      if (type === 'telegram')
        loginFormDomRef.value?.confirmTelegramLogin?.(params)
      else
        loginFormDomRef.value?.confirmGoogleLogin?.(params, '/b2c')
    }, 100)
  }
}
// 加密key中
readOnlyValue(window, 'ofgi6fd', toBase64('*&A)A*', true))

defineExpose({
  open: generateOpenFn('open'),
  openLogin: generateOpenFn<OpenAuthModalFnProps>('login'),
  openRegister: generateOpenFn<OpenAuthModalFnProps>('register'),
  openForgetPassword: generateOpenFn<OpenAuthModalFnProps>('forgetPassword'),
  close: onClose,
  confirmTelegramLogin: generateToLoginFn<TgUserData>('telegram'),
  confirmGoogleLogin: generateToLoginFn<{ code: string }>('google'),
})
</script>

<template>
  <ClientOnly>
    <AModal
      v-model:open="visibleRef"
      wrapClassName="auth-modal-scoped"
      :width="$bp.tabletl ? 850 : 450"
      closable
      centered
      destroyOnClose
      :maskClosable="false"
      :title="null"
      :footer="null"
      @cancel="onClose"
    >
      <div class="auth-form-scoped w-full grid grid-cols-1 tabletl:grid-cols-2 tabletl:min-h-125">
        <div class="auth-form-scoped-left w-full hidden tabletl:flex items-center justify-center">
          <div class="lottie-container w-[80%] h-[80%]" />
        </div>
        <div class="auth-form-scoped-right p-7.5 tabletl:p-10">
          <LoginForm
            v-if="showFormTypeRef === 'login'"
            ref="loginFormDomRef"
            :loginSuccessCallback="handleLoginSuccess"
            @changePages="changePages"
          />
          <RegisterForm v-else-if="showFormTypeRef === 'register'" v-bind="commonFormConfig" />
          <ForgetPasswordForm v-else-if="showFormTypeRef === 'forgetPassword'" v-bind="commonFormConfig" />
        </div>
      </div>
    </AModal>
  </ClientOnly>
</template>

<style lang="scss">
.ant-modal-wrap.auth-modal-scoped {
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
    background: linear-gradient(180deg,
    rgb(46 63 116 / 100%) 0%,
    rgb(35 44 79 / 100%) 10%);
    border-radius: 10px 20px 20px 10px;

    @media bp-lt-tabletl {
      border-radius: 16px;
    }

    .ant-modal-close-x {
      font-size: 23px;
      color: var(--secondary-text);
    }

    .ant-modal-body {
      color: var(--secondary-text);
    }

  }
}

.auth-form-scoped {
  &-left {
    background: linear-gradient(to bottom right, #2680eb, #2a47ab);
  }

  &-right {
    background: linear-gradient(180deg,
    rgb(46 63 116 / 100%) 0%,
    rgb(35 44 79 / 100%) 10%);

    .ant-form .ant-form-item .ant-form-item-control-input {
      min-height: 40px;
      font-size: 14px;
    }

    .ant-form .ant-form-item .ant-form-item-explain-connected {
      font-size: 14px;
    }

    .ant-form .ant-input-affix-wrapper .ant-input-suffix .ant-input-clear-icon {
      font-size: 14px;
      color: #8098BF;
    }

    .ant-input-affix-wrapper >input.ant-input:focus {
      box-shadow: 0 0 0 1000px #13192E inset !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 1000px #13192E inset !important;
      -webkit-text-fill-color: #E2E8F2 !important;
    }
  }
}
</style>
