<script lang="ts" setup>
import { LoadingOutlined } from '@ant-design/icons-vue'
import { useCaptcha } from '../hooks/useCaptcha'

const $props = withDefaults(defineProps<{
  modelValue?: string
}>(), {
  modelValue: '',
})

const emits = defineEmits<{
  (e: 'update:modelValue', v: string): void
  (e: 'changeId', v: string): void
}>()

const captcha = useModel($props, 'modelValue')

const { loading, captchaRef, getCaptchaFn } = useCaptcha(true, v => emits('changeId', v?.id ?? ''))

defineExpose({ getCaptchaFn })
</script>

<template>
  <div class="grid grid-cols-[1fr_120px] items-center gap-x-2.5">
    <AInput
      v-model:value="captcha"
      class="!h-[40px] !text-[14px] !p-[11px_12px]"
      :placeholder="$t('h8J4CZfaz6x5EXJfLkEy')"
      allowClear
      :maxLength="5"
      inputmode="numeric"
      data-test-id="30Ii"
    >
      <template #prefix>
        <i class="i-ant-design:safety-outlined !text-[#266EF1] !text-[16px]" />
      </template>
    </AInput>
    <div v-if="loading" class="h-[40px] flex items-center justify-center">
      <LoadingOutlined class="text-3xl text-[#E8EAED]" />
    </div>
    <img
      v-else
      class="h-[40px] cursor-pointer bg-[#E8EAED] rounded-[10px]"
      :src="captchaRef?.data"
      alt="Failed to load"
      data-test-id="my69"
      @click="getCaptchaFn"
    >
  </div>
</template>
