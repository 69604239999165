<script lang="ts" setup>
import type { TgUserData } from '../type'
import { getIsToUrlMode, handleToTelegramLogin } from '../hooks/loginUtils'

const emits = defineEmits<{
  (e: 'callback', user: TgUserData): void
  (e: 'rejectCallback',): void
}>()

const tgCheckDomRef = ref<HTMLElement | null>(null)

const getClientFn = () => (window as any)?.Telegram?.Login?.auth

function setupScript() {
  if (import.meta.env.SSR || !document || getClientFn() || getIsToUrlMode())
    return
  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = 'https://telegram.org/js/telegram-widget.js'
  tgCheckDomRef.value?.appendChild?.(script)
}

function startCheck() {
  const clientFn = getClientFn()
  if (getIsToUrlMode() || !clientFn) {
    handleToTelegramLogin()
    return
  }
  clientFn?.(
    { bot_id: env.test ? 5613182346 : 5612411988, request_access: true },
    (data: TgUserData) => {
      data ? emits('callback', data) : emits('rejectCallback')
    },
  )
}

onMounted(setupScript)

defineExpose({ startCheck })
</script>

<template>
  <div ref="tgCheckDomRef" class="flex-shrink-0">
    <slot :startCheck="startCheck" />
  </div>
</template>
