import axios from 'axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'ant-design-vue'
import { ApiCode, ApiError, isTimeout } from '../helpers'
import { getReqHeaders } from '../getReqHeaders'

export { server, createRequest }
const VITE_BUILD_MODE = import.meta.env.VITE_BUILD_MODE as any
const baseURL: any = {
  staging: 'https://swap.tron-gas.net/v1',
  development: '/pay',
  production: 'https://swap.ttpay.io/v1',
}
const server = axios.create({
  // baseURL: 'http://swap.tron-gas.net/v1',
  // baseURL: 'http://192.168.2.111:9999/v1',
  baseURL: baseURL[VITE_BUILD_MODE],
  timeout: 10 * 1000,
  // 自定义属性
  resultType: 'data',
  joinTime: true,
  errorMessageMode: 'message',
  checkCode: true,
})

/* #B 请求拦截器（先 use 后执行） */
// 处理全局请求 Header
server.interceptors.request.use(async (req) => {
  const headerConfig = await getReqHeaders()
  Object.keys(headerConfig).forEach((k) => {
    const v = headerConfig[k as keyof typeof headerConfig]
    v && req.headers.set(k, v)
  })
  // 删除多余的
  ;['Service-Name'].forEach(k => req.headers.delete(k))

  return req
})
/* #E */

/* #B 响应拦截器（先 use 先执行） */
// 格式化分页数据
server.interceptors.response.use((resp) => {
  const result = resp?.data

  if (!result)
    return resp

  if (result.pagination) {
    result.data = {
      list: result.data,
      pagination: result.pagination,
    }

    Reflect.deleteProperty(result, 'pagination')
  }

  return resp
})

// 统一 AxiosError 错误
server.interceptors.response.use(
  resp => resp,
  (error) => {
    if (axios.isAxiosError(error))
      throw error

    if (error instanceof Error)
      throw new axios.AxiosError(`${error.message}[AppError01]`)

    if (typeof error === 'object')
      throw new axios.AxiosError(`${JSON.stringify(error)}[AppError01]`)

    throw new axios.AxiosError(`${String(error)}[AppError01]`)
  },
)

// 处理 checkCode
server.interceptors.response.use((resp) => {
  if (resp.config.checkCode && resp?.data?.code !== ApiCode.NORMAL) {
    throw new axios.AxiosError(
      resp?.data?.msg ?? 'Unknown Error',
      resp?.data?.code ?? ApiCode.UNKNOWN,
      resp.config,
      resp.request,
      resp,
    )
  }

  return resp
})

// 处理 errorMessageMode
server.interceptors.response.use(
  resp => resp,
  (error) => {
    let errorMsg = 'Unknown Error'

    if (axios.isAxiosError(error)) {
      // 请求超时
      if (isTimeout(error))
        errorMsg = 'Request Timeout'
      else
        errorMsg = error.response?.data?.msg || error.message || error.response?.statusText
    }
    else if (error instanceof Error) {
      errorMsg = error.message || errorMsg
    }

    if (error?.config?.errorMessageMode === 'message')
      message.error(errorMsg)

    throw error
  },
)

// 处理 resultType
server.interceptors.response.use(
  (resp) => {
    const { resultType } = resp.config

    if (resultType === 'api')
      return resp.data
    else if (resultType === 'axios')
      return resp

    return resp.data?.data
  },
  (error) => {
    let code = error?.response?.data?.code || error?.response?.status || ApiCode.UNKNOWN
    let msg = error?.response?.data?.msg || error?.message || error?.response?.statusText || 'Unknown Error'
    const data = error?.response?.data?.data || null

    if (isTimeout(error)) {
      code = ApiCode.TIMEOUT
      msg = 'Request Timeout'
    }

    throw new ApiError({ code, msg, data })
  },
)
/* #E */

function createRequest<Req, OResp, Resp = Api.TransformPageResult<OResp>>(
  id: string,
  paramBuilder: (params: any) => any,
) {
  type ReqConfig = Partial<AxiosRequestConfig>

  // 返回 Axios 的 Response
  async function request<Rt extends 'axios'>(
    reqData: Req,
    options: ReqConfig & { resultType: Rt }
  ): Promise<AxiosResponse<Resp>>
  // 返回 API 数据
  async function request<Rt extends 'api'>(reqData: Req, options: ReqConfig & { resultType: Rt }): Promise<Resp>
  // 返回 API 数据中的 data 字段值，默认
  async function request(reqData: Req, config?: ReqConfig): Promise<Api.GetDataField<Resp>>
  async function request(reqData: Req, config?: ReqConfig): Promise<any> {
    const params = paramBuilder(reqData)
    const res = await server.request({
      apiName: id,
      ...params,
      ...config,
    })

    return res as Api.GetDataField<Resp>
  }

  request.id = id

  return request
}

declare module 'axios' {
  interface AxiosRequestConfig {
    /**
     * 接口返回的数据类型
     *
     * - `axios`：Axios 的 Response
     * - `api`：API 数据完整结构
     * - `data`：API 数据中的 data 字段值，**默认**
     */
    resultType?: Api.Options['resultType']
    /**
     * 是否加入查询参数时间戳
     *
     * - `true`：**默认**
     */
    joinTime?: Api.Options['joinTime']
    /**
     * 当接口报错时，弹出 message 的类型
     *
     * - `message`：调用 Ant 的 `message.error` 弹出提示，**默认**
     * - `none`：不弹出提示
     */
    errorMessageMode?: Api.Options['errorMessageMode']
    /**
     * 是否检查 API 的 code 字段
     *
     * - `true`：当 code 非 0 时报错，**默认**
     * - `false`：不检查
     */
    checkCode?: boolean
  }
}
