import { apis } from '~/api'

export const icCache = {
  key: 'ic',
  set(value: string) {
    if (value && !import.meta.env.SSR)
      localStorage.setItem(this.key, value)
  },
  get() {
    if (import.meta.env.SSR)
      return ''
    return localStorage.getItem(this.key) ?? ''
  },
  remove() {
    if (import.meta.env.SSR)
      return
    localStorage.removeItem(this.key)
  },
}

export function run() {
  const route = useRoute()
  const { account } = tronComp.useAccountCurrent()

  watch(() => route.query, (query) => {
    const ic = (query[icCache.key]) as string
    if (ic)
      icCache.set(ic)
  }, { immediate: true })

  watch(() => account.address, (address) => {
    const ic = icCache.get()
    if (address && ic) {
      apis.promoteBind({
        requestBody: {
          address,
          invite_code: ic,
        },
      }, { errorMessageMode: 'none' }).then((res) => {
        // 清楚缓存中的ic
        icCache.remove()
      })
    }
  }, { immediate: true })
}
