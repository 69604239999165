import { QueryClient } from '@tanstack/vue-query'

export { queryClient }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      getNextPageParam: (lastPage: any) => {
        const { pagination } = lastPage ?? {}

        if (pagination?.has_more) {
          return {
            page: (pagination.page ?? 0) + 1,
            page_size: pagination?.page_size ?? 10,
          }
        }
      },
      getPreviousPageParam: (lastPage: any) => {
        const { pagination } = lastPage ?? {}

        return {
          page: (pagination.page ?? 1) - 1,
          page_size: pagination?.page_size ?? 10,
        }
      },
    },
  },
})
