export default {
  "components-footer": () => import("D:/Project/vue/frontend_new/src/layouts/components/Footer/index.ts").then(m => m.default || m),
  "components-footer-src-footer": () => import("D:/Project/vue/frontend_new/src/layouts/components/Footer/src/Footer.vue").then(m => m.default || m),
  "components-header": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/index.ts").then(m => m.default || m),
  "components-header-src-header": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/src/Header.vue").then(m => m.default || m),
  "components-header-src-logo": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/src/Logo.vue").then(m => m.default || m),
  "components-header-src-navigation": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/src/Navigation.vue").then(m => m.default || m),
  "components-header-src-service": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/src/Service.vue").then(m => m.default || m),
  "components-header-src-side-navigation": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/src/SideNavigation.vue").then(m => m.default || m),
  "components-header-src-user": () => import("D:/Project/vue/frontend_new/src/layouts/components/header/src/User.vue").then(m => m.default || m),
  "components-to-top": () => import("D:/Project/vue/frontend_new/src/layouts/components/toTop/index.ts").then(m => m.default || m),
  "components-to-top-src-to-top": () => import("D:/Project/vue/frontend_new/src/layouts/components/toTop/src/ToTop.vue").then(m => m.default || m),
  default: () => import("D:/Project/vue/frontend_new/src/layouts/default.vue").then(m => m.default || m)
}