<script lang="ts" setup>
import { useVModel} from '@vueuse/core';
// 用于替代aModel.error、aModel.success这个组件失效

const props = withDefaults(defineProps<{
  open: boolean,
  state?: boolean,
  content : string
}>(), {
  state: true,
})

const emits = defineEmits(['close','update:open']);
const open = useVModel(props,'open', emits)
function close(){
  open.value = false
  emits('close')
}
</script>

<template>
  <AModal
    v-model:open="open"
    wrapClassName="ant-cover__basic-modal"
    :title="$t('mxo0cnGrkxXuDuAchSx')"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    :footer="null"
  >
    <div class="order-create flex flex-col items-center">
      <img v-if="props.state" class="w-60px h-60px mb-20px" src="~/assets/icons/success.svg">
      <i v-else :class="`i-local:fail`" class=" text-60px mb-20px" />
      <p class="title text-18px text-primary-100 leading-6 mb-19px">
        <slot>
          {{ props.content }}
        </slot>
      </p>
      <AButton class="comfirm-btn w-300px mt-20px" @click="close">
        {{ $t('UENBgObobQIqSj65k6uBN') }}
      </AButton>
    </div>
  </AModal>
</template>
