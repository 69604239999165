import { message, notification } from 'ant-design-vue'
import type { Plugin } from 'vue'

import Notification from './Notification.vue'

const NOTIF_KEY = 'update-app-notification'
const INTERVAL = 30 * 60 * 1000 // 两次检查间隔30分钟
let nextCheckTime = Date.now()

export const plugin: Plugin = {
  install(_app, { version }: { version: string }) {
    const tryCheck = (immediate = false) => {
      if (nextCheckTime <= Date.now() || immediate) {
        const checked = checkVersion(version)
        if (checked)
          nextCheckTime = Date.now() + INTERVAL
      }
    }

    window.addEventListener('focus', () => tryCheck(true))
    setInterval(tryCheck, INTERVAL)
    setTimeout(tryCheck, 3000)
  },
}

function checkVersion(currentVersion: string) {
  const { config } = useAppState()
  let webVersion = config.value?.version.web_version ?? undefined
  if( env.dev ){ webVersion = undefined}
  if (!webVersion)
    return false

  const force = webVersion[0] === 'f' // 是否强制更新
  const lastVersion = force ? webVersion.substring(1) : webVersion
  const { vueApp } = useNuxtApp()
  if (currentVersion !== lastVersion) {
    notification.open({
      key: NOTIF_KEY,
      class: 'update-app-notification',
      message: '',
      btn: () => null,
      closeIcon: () => null,
      description: () =>
        h(Notification, {
          force,
          translate: vueApp.config.globalProperties.$t,
          onLater: () => notification.close(NOTIF_KEY),
          onReload: () => location.reload(),
        }),
      duration: null,
      placement: 'topRight',
    })
  }
  return true
}
