import { createGlobalState } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { tronComp } from '~/composables/tron'

export { useAppState }

const useAppState = createGlobalState(() => {
  const configQuerier = useConfigQuerier()
  const usableResourceQuerier = useUsableResourceQuerier()
  return { ...configQuerier, ...usableResourceQuerier }
})

function useConfigQuerier() {
  const { account } = tronComp?.useState()

  const configQuerier = useQuery({
    queryKey: [apis.initConfig.id, account],
    staleTime: 5 * 1000 * 60,
    keepPreviousData: true,
    queryFn: () => apis.initConfig({ trx_address: account.address || undefined }, { errorMessageMode: 'none' }),
  })
  const config = computed(() => configQuerier.data.value)

  return { config, configQuerier }
}

function useUsableResourceQuerier() {
  // 可用能量查询
  const usableEnergyQuerier = useQuery({
    queryKey: [apis.usableEnergy.id],
    queryFn: () => apis.usableEnergy(undefined, { errorMessageMode: 'none' }),
  })
  const usableEnergy = computed(() => usableEnergyQuerier.data.value)

  // 可用带宽查询...

  return { usableEnergy, usableEnergyQuerier }
}
