import type { DefineComponent } from 'vue'

export { declareComponentOn }

/**
 * 使事件监听函数的参数具有类型提示
 */
function declareComponentOn<
  C extends DefineComponent<{}, {}, any>,
  ES extends GetEmits<C['emits']>,
  NS extends keyof ES,
>(_c: C, _n: NS, fn: ES[NS]) {
  return fn
}

type GetEmits<T> = T extends string[] ? never : NonNullable<T>
