class ImToken {
  /**
   * 判断是否是imtoken环境
   */
  public isImToken() {
    if (import.meta.env.SSR)
      return
    return !!window.imToken
  }

  /**
   * 拉起imtoken连接钱包操作
   */
  public async showAccountSwitch() {
    if (this.isImToken()) {
      window.imToken.callAPI('user.showAccountSwitch', { chainType: 'TRON' }, (err: any) => {
        if (!err)
          window.location.reload()
      })
    }
  }

  /**
   * imtoken setTitle
   * @param title
   */
  public async setTitle(title: string) {
    if (this.isImToken())
      window.imToken.callAPI('navigator.setTitle', title)
  }
}
export { ImToken }
