export function useBoolean(initValue: Ref<boolean> | boolean = false) {
  const is = ref(initValue)

  const setTrue = () => {
    is.value = true
  }
  const setFalse = () => {
    is.value = false
  }
  const toggle = () => {
    is.value = !is.value
  }

  return reactive({ is, setTrue, setFalse, toggle })
}
