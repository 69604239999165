import Cryptojs from 'crypto-js'

const Base64 = Cryptojs.enc.Base64

/**
 * base64转成正常字符串
 * @param base64Str 值
 */
export function formatBase64(base64Str: string) {
  if (!base64Str || typeof base64Str !== 'string')
    return ''
  return Cryptojs.enc.Utf8.stringify(Base64.parse(base64Str))
}

/**
 * 转为base64字符串
 * @param str 值
 * @param removePadding 是否清除后面的==号 default: false
 */
export function toBase64(str: string | number, removePadding = false) {
  if (!['string', 'number'].includes(typeof str) || !str)
    return ''
  const result = Base64.stringify(Cryptojs.enc.Utf8.parse(String(str)))
  if (removePadding)
    return result.replace(/=+$/, '')
  return result
}

/**
 * 设置只读&不可枚举的对象值
 * @param obj 对象
 * @param key 字段
 * @param value 值
 */
export function readOnlyValue(obj: object, key: string, value: any) {
  if (!obj || !key)
    return
  Object.defineProperty(obj, key, {
    value,
    enumerable: false,
    writable: false,
  })
}

function ks(c: string[]) {
  let d = ''
  if (!window)
    return d
  c.forEach((k) => {
    const v = (window as any)?.[k]
    v && (d += formatBase64(v))
  })
  return d
}

export function lrfhjkask2gd(b: string, c = ['kfds3gf', 'ofgi6fd', 'g6ggva']) {
  const d = ks(c)
  if (!b || !c.length || !d)
    return ''
  const e = Cryptojs.enc.Utf8.parse(b)
  const f = Cryptojs.enc.Utf8.parse(d)
  const g = { mode: Cryptojs.mode.ECB, padding: Cryptojs.pad.Pkcs7 }
  return Cryptojs.AES.encrypt(e, f, g).toString()
}
