import { useTronState } from './useTron'
import { useTronAccount } from './useTronAccount'
import { useTronAccountCurrent } from './useTronAccountCurrent'

export { useRentDuration } from './useRentDuration'
export { useResource } from './useResource'
export { useResourceTypeText } from './useResourceTypeText'

export const tronComp = {
  useState: useTronState,
  useAccount: useTronAccount,
  useAccountCurrent: useTronAccountCurrent,
}
