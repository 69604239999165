import { useAppBreakpoints } from '@peng_kai/theme/vue'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      bp: useAppBreakpoints(),
    },
  }
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $bp: ReturnType<typeof useAppBreakpoints>
  }
}

declare module '#app' {
  interface NuxtApp {
    $bp: ReturnType<typeof useAppBreakpoints>
  }
}
