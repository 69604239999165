<script lang="ts" setup>
import { appendURLQuery } from '@/utils'

const visible = ref(false)

function getOpenLink() {
  const params = {
    url: location.href,
    action: 'open',
    protocol: 'tronlink',
    version: '1.0',
  }
  const url = appendURLQuery('tronlinkoutside://pull.activity', [{ key: 'param', value: JSON.stringify(params) }])

  return url
}
// 默认成功
function open(options: {}) {
  visible.value = true
}

function close() {
  visible.value = false
}

defineExpose({
  open,
  close,
})
</script>

<!-- TP7EbJPVKuwQjoUkwZwfR5sRPidCUxHf9d -->
<template>
  <div>
    <ADrawer
      rootClassName="ant-cover__basic-drawer"
      :open="visible"
      :maskClosable="true"
      :closable="true"
      height="45%"
      placement="bottom"
      :title="$t('xb8bxudTC8ctGbXrUgD4F')"
      @close="close"
    >
      <div class="item">
        <p>{{ $t('8hXBDUys92ND1Ly5wTxtn') }}</p>
        <AButton class="comfirm-btn flex-center!" :href="getOpenLink()" target="_self">{{ $t('MZI55A2VCf3NJsoeQYxZ-') }}</AButton>
      </div>
      <hr>
      <div class="item">
        <p>{{ $t('CikGlX7sGX2mQtTNQ9W09') }}</p>
        <AButton
          class="comfirm-btn flex-center!"
          :href="$i18n.locale === 'zh' ? 'https://www.tronlink.org/cn/dlDetails/' : 'https://www.tronlink.org/dlDetails/'"
          target="_self"
        >
          {{ $t('1inv9OtvfCB8bsFndl7C_') }}
        </AButton>
      </div>
    </ADrawer>
  </div>
</template>

<style lang="scss" scoped>
hr {
  margin: 20px 0;
  border: none;
  border-top: 1.5px dashed var(--bg-one);
}

.item {
  p {
    margin-bottom: 12px;
    line-height: 2;
    color: #8098bf;
  }
}
</style>
