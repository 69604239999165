import { createGlobalState, useTimeoutPoll } from '@vueuse/core'
import { tron } from '~/utils/tron-v2'

export { useTronState }

const useTronState = createGlobalState(() => {
  const account = useCurrentAccount()

  return { account }
})

function useCurrentAccount() {
  const account = reactive({
    address: '',
    chainId: '',
  })

  // PC 环境
  if (tron.tronWeb && !!unref(client) && client.isPc) {
    const updateAccount = async ({ chainId, address }: { chainId?: string; address?: string }) => {
      const _account: typeof account = {
        chainId: chainId || '',
        address: address || '',
      }

      if (!_account.address)
        _account.address = tron?.tronLink?.ready ? tron.tronWeb?.defaultAddress?.base58 || '' : ''

      if (!_account.chainId && _account.address)
        _account.chainId = await tron.fetchChainId()

      Object.assign(account, _account)
    }

    window.addEventListener('message', (ev) => {
      const msg = ev.data?.message

      if (['connect', 'accountsChanged'].includes(msg?.action))
        updateAccount({})

      if (msg?.action === 'disconnect')
        updateAccount({ chainId: '', address: '' })

      if (msg?.action === 'setNode')
        updateAccount({ chainId: msg?.data?.node?.chainId ?? '' })
    })

    let tintervalCount = 3
    const intervalTimer = setInterval(() => {
      if (tintervalCount-- < 0)
        clearInterval(intervalTimer)
      updateAccount({})
    }, 1000)
  }

  // Mobile 环境
  if (tron.tronWeb && client.isMobile) {
    useTimeoutPoll(
      async () => {
        const _account: typeof account = {
          chainId: account.chainId,
          address: tron.tronWeb?.defaultAddress?.base58 || '',
        }
        const canGetChainId = _account.address && !_account.chainId

        if (canGetChainId)
          _account.chainId = await tron.fetchChainId()

        Object.assign(account, _account)
      },
      1000,
      { immediate: true },
    ).resume()
  }

  return readonly(account)
}
