import type { WatchStopHandle } from 'vue'
import { haveTgAuthResult } from './loginUtils'

let watchErFunc: WatchStopHandle | null = null

export function setupWatchLoginMode() {
  if (watchErFunc) {
    watchErFunc?.()
    watchErFunc = null
  }
  const { currentRoute, replace } = useRouter()
  watchErFunc = watch(() => currentRoute.value.query, (query) => {
    if (query?.code && query?.prompt && (query?.scope as string)?.indexOf?.('https://www.googleapis.com/auth/userinfo.email') > -1) {
      globalModal?.AuthModal?.confirmGoogleLogin?.({ code: String(query?.code) })
      replace({ query: {} })
    }
    else {
      const tgUrlRes = haveTgAuthResult()
      tgUrlRes?.id && tgUrlRes?.hash && globalModal?.AuthModal?.confirmTelegramLogin?.(tgUrlRes)
    }
  }, { immediate: true })
}
