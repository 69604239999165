<script lang="ts" setup>
import { antdUseFormFn, getRegisterFormRule } from '../config/formRules'
import FormTitle from './FormTitle'
import CaptchaInput from './CaptchaInput.vue'
import { icCache } from '~/components/app-provider/src/invitationCode'

const $props = defineProps<{ backLoginFn: () => void }>()

const captchaInputDomRef = ref<{ getCaptchaFn: () => Promise<void> } | null>()
const changeCaptchaId = (id: string) => (formData.captcha_id = id ?? '')

const loading = ref(false)
const formData = reactive({
  account: '',
  password: '',
  password_confirm: '',
  captcha: '',
  captcha_id: '',
})

const { validate, validateInfos } = antdUseFormFn(formData, getRegisterFormRule(toRef(formData, 'password')))

function handleResponseError(code: number, msg: string) {
  const email = formData.account
  if (code === 15024) {
    window.location.href = `/console/auth/pending-verify?email=${email}&type=1`
  }
  else {
    aMessage.error(msg)
    captchaInputDomRef.value?.getCaptchaFn?.()
  }
}

// 注册
function handleRegister() {
  if (loading.value)
    return
  validate().then(() => {
    loading.value = false
    apis.authRegister(
      {
        requestBody: {
          ...formData,
          password: lrfhjkask2gd(formData.password),
          password_confirm: lrfhjkask2gd(formData.password_confirm),
          invite_code: icCache.get(),
        },
      },
      { errorMessageMode: 'none' },
    )
      .catch((err) => {
        handleResponseError(err.code, err.message)
      })
      .finally(() => {
        loading.value = false
      })
  })
}
</script>

<template>
  <div>
    <FormTitle :title="$t('hoEN8RZzz8l0qGDiZs6k')" />

    <AForm @keypress.enter="handleRegister">
      <AFormItem name="account" v-bind="validateInfos.account">
        <AInput
          v-model:value="formData.account"
          class="!h-[40px] !text-[14px] !p-[11px_12px]"
          :placeholder="$t('aaboGsyu6pHcKErDprVK')"
          allowClear
        >
          <template #prefix>
            <i class="i-ant-design:user-outlined !text-[#266EF1] !text-[16px]" />
          </template>
        </AInput>
      </AFormItem>
      <AFormItem name="password" v-bind="validateInfos.password">
        <AInputPassword
          v-model:value="formData.password"
          class="!h-[40px] !text-[14px] !p-[11px_12px]"
          :placeholder="$t('0We87UJbtA0kpmaH3h5xT')"
          allowClear
        >
          <template #prefix>
            <i class="i-ant-design:lock-outlined !text-[#266EF1] !text-[16px]" />
          </template>
        </AInputPassword>
      </AFormItem>
      <AFormItem name="confirmPassword" v-bind="validateInfos.password_confirm">
        <AInputPassword
          v-model:value="formData.password_confirm"
          class="!h-[40px] !text-[14px] !p-[11px_12px]"
          :placeholder="$t('ejAdAy20fTfZBeJiIbTVa')"
          allowClear
        >
          <template #prefix>
            <i class="i-ant-design:lock-outlined !text-[#266EF1] !text-[16px]" />
          </template>
        </AInputPassword>
      </AFormItem>

      <AFormItem name="captcha" v-bind="validateInfos.captcha">
        <CaptchaInput ref="captchaInputDomRef" v-model="formData.captcha" @changeId="changeCaptchaId" />
      </AFormItem>

      <AButton
        class="comfirm-btn !h-[40px] !text-[17px] w-full"
        :loading="loading"
        data-test-id="t1mf"
        @click="handleRegister"
      >
        {{ $t('hoEN8RZzz8l0qGDiZs6k') }}
      </AButton>
      <div
        class="mt-4 w-fit cursor-pointer text-[#8098BF] text-[14px] hover:text-[#266ef1] hover:underline" data-test-id="04z7"
        @click="$props?.backLoginFn?.()"
      >
        {{ $t('4OldtQqw5zgalFoT6vyl') }}
      </div>
    </AForm>
  </div>
</template>
