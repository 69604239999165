<script lang="ts" setup>
import { antdUseFormFn, getForgetPasswordFormRule } from '../config/formRules'
import CaptchaInput from './CaptchaInput.vue'
import FormTitle from './FormTitle'

const $props = defineProps<{ backLoginFn: () => void }>()

const captchaInputDomRef = ref<{ getCaptchaFn: () => Promise<void> } | null>()
const changeCaptchaId = (id: string) => (formData.captcha_id = id ?? '')

const loading = ref(false)
const formData = reactive({
  email: '',
  captcha: '',
  captcha_id: '',
})

const { validate, validateInfos } = antdUseFormFn(formData, getForgetPasswordFormRule())

function sendCode() {
  if (loading.value)
    return
  validate().then(() => {
    loading.value = true
    apis.authForgetPassword({
      requestBody: formData,
    })
      .then(() => {
        window.location.href = `/console/auth/pending-verify?email=${formData.email}&type=2`
      })
      .catch(() => {
        captchaInputDomRef.value?.getCaptchaFn?.()
      })
      .finally(() => {
        loading.value = false
      })
  })
}
</script>

<template>
  <div>
    <FormTitle :title="$t('mylkliFjm4MTzpLnel9k')" />

    <AForm @keypress.enter="sendCode">
      <AFormItem name="email" v-bind="validateInfos.email">
        <AInput
          v-model:value="formData.email"
          class="!h-[40px] !text-[14px] !p-[11px_12px]"
          :placeholder="$t('aaboGsyu6pHcKErDprVK')"
          allowClear
        >
          <template #prefix>
            <i class="i-ant-design:user-outlined !text-[#266EF1] !text-[16px]" />
          </template>
        </AInput>
      </AFormItem>
      <AFormItem name="captcha" v-bind="validateInfos.captcha">
        <CaptchaInput ref="captchaInputDomRef" v-model="formData.captcha" @changeId="changeCaptchaId" />
      </AFormItem>

      <AButton
        class="comfirm-btn w-full !h-[40px] !text-[17px]"
        :loading="loading"
        data-test-id="5e6e"
        @click="sendCode"
      >
        {{ $t('rLcOwFvSj7uZg_9prHm13') }}
      </AButton>
      <div
        class="mt-4 w-fit cursor-pointer text-[#8098BF] text-[14px] hover:text-[#266ef1] hover:underline" data-test-id="45lq"
        @click="$props?.backLoginFn?.()"
      >
        {{ $t('4OldtQqw5zgalFoT6vyl') }}
      </div>
    </AForm>
  </div>
</template>
