import { Buffer } from 'buffer'
import BigNum from 'bignumber.js'

function toBigNum(amount: string | number | BigNum = 0) {
  if (BigNum.isBigNumber(amount))
    return amount

  if (typeof amount === 'string' && (amount.indexOf('0x') === 0 || amount.indexOf('-0x') === 0))
    return new BigNum(amount.replace('0x', ''), 16)

  return new BigNum(amount.toString(10), 10)
}

function toFromUtf8(val: string) {
  const res = Buffer.from(val, 'utf8').toString('hex')
  return res.indexOf('0x') === 0 ? res : `0x${res}`
}

function toFromDecimal(value: any) {
  if (!value)
    return ''
  const number = toBigNum(value)
  const result = number.toString(16)
  return number.isLessThan(0) ? `-0x${result.slice(1)}` : `0x${result}`
}

function toHex(val: boolean | BigNum | string | object) {
  if (typeof val === 'boolean')
    return toFromDecimal(+val)

  if (BigNum.isBigNumber(val))
    return toFromDecimal(val)

  if (typeof val === 'object')
    return toFromUtf8(JSON.stringify(val))

  if (typeof val === 'string') {
    if (val.indexOf('-0x') === 0)
      return toFromDecimal(val)

    if (val.indexOf('0x') === 0)
      return val

    if (!Number.isFinite(val))
      return toFromUtf8(val)
  }

  return toFromDecimal(val)
}

class Bitpie {
  public get bitpie(): Record<string, any> | undefined {
    if (import.meta.env.SSR)
      return
    return (window as any)?.Bitpie
  }

  /** 判断是否是Bitpie环境 */
  public isBitpie() {
    if (import.meta.env.SSR)
      return false
    return !!this.bitpie
  }

  public toBigNumber = toBigNum

  public fromDecimal = toFromDecimal

  public toHex = toHex
}

export const bitpie = new Bitpie()
