<!-- eslint-disable import/no-mutable-exports -->

<script lang="ts">
import { ConfigProvider as AConfigProvider, Spin as ASpin } from 'ant-design-vue'
import { run } from './invitationCode'
import { themes } from './theme'


export { $t, $d, localePath, currentLocale }

type NuxtApp = ReturnType<typeof useNuxtApp>

let $t: NuxtApp['$i18n']['t']
let $d: NuxtApp['$i18n']['d']
let localePath: ReturnType<typeof useLocalePath>
let currentLocale: NuxtApp['$i18n']['locale']

function setupProvider(nuxtApp: NuxtApp) {
  $t = nuxtApp.$i18n.t
  $d = nuxtApp.$i18n.d
  localePath = useLocalePath()
  currentLocale = nuxtApp.$i18n.locale

}
</script>

<script setup lang="ts">
const { account } = tronComp.useAccountCurrent()
// 设置默认Spin
ASpin.setDefaultIndicator({
  indicator: h('i', { class: 'i-svg-spinners:180-ring-with-bg block' }),
})

run()
setupProvider(useNuxtApp())

// todo 旧的切换钱包弹出刷新model 后删掉
function switchWalletAddress() {
  const route = useRoute()
  const refreshable = computed(() => ['c2c'].includes(route.path.replace('/', '')))

  watch(() => account.address, (oldVal, newVal) => {
    if (!newVal)
      return

    if (oldVal !== newVal && refreshable.value)
      globalModal.Refresh?.open()
  })
}
</script>

<template>
  <AConfigProvider
    :theme="themes"
    :locale="JSON.parse($t('antd'))"
  >
    <slot />
  </AConfigProvider>
</template>
