import { whenever } from '@vueuse/core'
import { useTronState } from '~/composables/tron/useTron'
import { TronChainId } from '~/utils/tron-v2'

export { useInspectTronChaubId }

function useInspectTronChaubId() {
  const { account: current } = useTronState()
  const route = useRoute()

  const open = computed(() => {
    const routeName = String(route.name)
    const allowNames = ['c2c___', 'b2c___', 'swap___']
    return env.prod && current.chainId && current.chainId !== TronChainId.Main && allowNames.some(name => routeName.startsWith(name))
  })
  whenever(open, () => globalModal.TronNetHint?.open())
}
