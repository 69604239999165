<script lang="ts" setup>
import { getIsToUrlMode, getWebsiteUrl } from '../hooks/loginUtils'

const emits = defineEmits<{
  (e: 'callback', user: { code: string }): void
  (e: 'rejectCallback', data: any): void
}>()

const googleCheckDomRef = ref<HTMLElement | null>(null)
const isHidden = ref(true)

const getClientFn = () => (window as any)?.google?.accounts?.oauth2?.initCodeClient

function setupScript() {
  if (import.meta.env.SSR || !document || getClientFn()) {
    if (getClientFn())
      isHidden.value = false
    return
  }
  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = 'https://accounts.google.com/gsi/client'
  googleCheckDomRef.value?.appendChild?.(script)
  script.onload = () => {
    if (getClientFn())
      isHidden.value = false
  }
}

function startCheck() {
  getClientFn()?.({
    client_id: '995202276730-31eo0s958blcdkvdpqncjriktehrrd67.apps.googleusercontent.com',
    scope: 'email profile',
    redirect_uri: `${getWebsiteUrl()}/b2c`,
    ux_mode: getIsToUrlMode() ? 'redirect' : 'popup',
    callback(data: any) {
      data.error ? emits('rejectCallback', data) : emits('callback', data)
    },
  })?.requestCode?.()
}

onMounted(setupScript)

defineExpose({ startCheck })
</script>

<template>
  <div ref="googleCheckDomRef" class="flex-shrink-0">
    <slot v-if="!isHidden" :startCheck="startCheck" />
  </div>
</template>
