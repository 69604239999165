import fpPromise from '@fingerprintjs/fingerprintjs'
import { isObject } from '@vueuse/core'

function withValue<T>(val: T) {
  if (isObject(val) && Object.prototype.hasOwnProperty.call(val, 'value')) {
    const v = (val as unknown as object & { value: T }).value
    return { value: v }
  }
  return { value: val }
}

async function getFingerprintAction() {
  try {
    const fp = await fpPromise.load()
    const r = await fp.get()
    return r || null
  }
  catch (error) {
    return null
  }
}

async function getDeviceInfo() {
  const r = await getFingerprintAction()
  if (!r)
    return ''
  const fpComp = r.components
  const info = {
    platform: withValue(fpComp.platform).value,
    timezone: withValue(fpComp.timezone).value,
    vendor: withValue(fpComp.vendor).value,
    languages: ((withValue(fpComp.languages).value) as unknown as string[][])?.join(','),
    screen_rolution: ((withValue(fpComp.screenResolution).value) as unknown as string[])?.join('x'),
    vendor_flavors: ((withValue(fpComp.vendorFlavors).value) as unknown as string[])?.join(','),
    visitor_id: r.visitorId,
  }
  return lrfhjkask2gd(JSON.stringify(info))
}

export function useFingerprint() {
  return { getFingerprintAction, getDeviceInfo }
}

export default useFingerprint()
