<script lang="ts" setup>
import { useTimeoutPoll } from '@vueuse/core'
import type { AuthFormType, LoginSuccessCallbackType } from '../type'
import { useLoginApiActions } from '../hooks/useLoginApiActions'
import { antdUseFormFn, getLoginFormRule } from '../config/formRules'
import { getWalletInfo, getWebsiteUrl } from '../hooks/loginUtils'
import TgCheck from './TgCheck.vue'
import GoogleCheck from './GoogleCheck.vue'
import CaptchaInput from './CaptchaInput.vue'
import FormTitle from './FormTitle'
import { icCache } from '~/components/app-provider/src/invitationCode'
import { tron as tronUtils } from '~/utils/tron-v2'

const $props = defineProps<{ loginSuccessCallback?: LoginSuccessCallbackType }>()

const emits = defineEmits<{ (e: 'changePages', type: AuthFormType): void }>()

const TronLoginConfirm = defineAsyncComponent(() => import('./TronLoginConfirm.vue'))

const { t } = useI18n()

const { emailLogin, telegramLogin, tronLogin, googleLogin } = useLoginApiActions(() => $props?.loginSuccessCallback?.())

const walletInfo = getWalletInfo()

const captchaInputDomRef = ref<{ getCaptchaFn: () => Promise<void> } | null>()
const changeCaptchaId = (id: string) => (formData.captcha_id = id ?? '')

const loginLoading = ref(false)
const formData = reactive({
  account: '',
  password: '',
  captcha: '',
  captcha_id: '',
})

const { validate, validateInfos } = antdUseFormFn(formData, getLoginFormRule())

type ILoginType = 'email' | 'tron' | 'telegram' | 'google'

const isOpenGoogleVerifyModal = ref(false)
const loginTypeOfGoogle = ref<ILoginType>('email')

async function handleGoogleVerify(security_code: string) {
  try {
    if (loginTypeOfGoogle.value === 'email') {
      const r = await handleLogin(security_code)
      return !!r
    }

    const isTron = loginTypeOfGoogle.value === 'tron'
    const isTg = loginTypeOfGoogle.value === 'telegram'

    const apiFn = isTron ? tronLogin : isTg ? handleTgLoginApi : confirmGoogleLogin

    const userData = isTron ? tronUserRes.value : isTg ? tgUserRes.value : googleUserRes.value

    const u = await apiFn({ ...(userData ?? {}), security_code } as any)
    return !!u
  }
  catch (error) {
    return false
  }
}

// 处理响应 code
function handleResponseError(code: number, msg: string, type: ILoginType, emailVal?: string) {
  const email = emailVal ?? formData.account
  if (code === 15024 && email) {
    window.location.href = `/console/auth/pending-verify?email=${email}&type=1`
    return
  }
  // 谷歌验证
  if (code === 15028) {
    isOpenGoogleVerifyModal.value && aMessage.error(msg)
    globalModal?.GoogleVerifyModal?.open?.({
      api: handleGoogleVerify,
      showSignRef: isOpenGoogleVerifyModal,
    })
    loginTypeOfGoogle.value = type
    return
  }
  aMessage.error(msg || t('9Y9Ffwf7tGXtOq58hVv7'))
  captchaInputDomRef.value?.getCaptchaFn?.()
}

// 登录
async function handleLogin(security_code?: string) {
  if (loginLoading.value)
    return null
  const bool = await validate().catch(() => null)
  if (!bool)
    return null
  try {
    loginLoading.value = true
    const userInfo = await emailLogin({
      ...formData,
      password: lrfhjkask2gd(formData.password),
      security_code,
    })
    loginLoading.value = false
    return userInfo
  }
  catch (error: any) {
    handleResponseError(error?.code, error?.message, 'email')
    loginLoading.value = false
    return null
  }
}

// 钱包登录
const showTronLoginModal = ref(false)

const tronLinkTimer = useTimeoutPoll(() => {
  if (tronUtils.address && !showTronLoginModal.value) {
    tronLinkTimer.pause()
    showTronLoginModal.value = true
  }
}, 800, { immediate: false })

function onTronLoginBtn() {
  if (!tronUtils.address) {
    globalModal.ConnectWallet?.open()
    tronLinkTimer.resume()
    return
  }
  showTronLoginModal.value = true
}

const tronUserRes = ref<Parameters<typeof tronLogin>[0]>()

// 用户确认钱包登录
async function confirmTronLinkLogin() {
  try {
    const address = tronUtils.address
    loginLoading.value = true
    const resp = await apis.authTronNonce({ requestBody: { address } }).catch(() => null)
    if (!resp?.nonce)
      return null
    const signResp = await tronUtils.emptySign(resp.nonce)
    if (signResp.err !== '') {
      const msg = signResp.err?.includes?.('Confirmation declined by user')
        ? t('rZb8Rj47FnThznb5BgQ9')
        : signResp.err?.includes?.('Not signedTx') ? t('gKatbECmT3767f51tR6W') : signResp.err
      aMessage.error(msg)
      loginLoading.value = false
      return null
    }

    if (!signResp?.res) {
      globalModal.Refresh?.open?.()
      return null
    }

    tronUserRes.value = {
      address,
      signature: signResp?.res,
      fingerprint: '',
      invite_code: icCache.get(),
    }
    const res = await tronLogin(tronUserRes.value).catch((err) => {
      handleResponseError(err?.code, err?.message, 'tron')
      return null
    })
    if (res)
      tronUserRes.value = void 0
    return res
  }
  finally {
    loginLoading.value = false
  }
}

// Telegram登录start
type TgParams = Parameters<typeof telegramLogin>[0]
const tgUserRes = ref<TgParams>()

async function confirmTelegramLogin(tgUser: TgParams) {
  tgUserRes.value = tgUser ? { ...tgUser, invite_code: icCache.get() } : void 0
  tgUser && handleTgLoginApi(tgUser)
}

async function handleTgLoginApi(requestBody: TgParams) {
  if (!requestBody || loginLoading.value)
    return null
  try {
    loginLoading.value = true
    const res = await telegramLogin(requestBody)
    if (res)
      tgUserRes.value = void 0
    return res
  }
  catch (error: any) {
    handleResponseError(error?.code, error?.message, 'telegram', error?.email)
    return null
  }
  finally {
    loginLoading.value = false
  }
}
// Telegram登录end

// Google登录start
const googleUserRes = ref<Parameters<typeof googleLogin>[0]>()

async function confirmGoogleLogin(response: { code: string }, suffixPath = '') {
  if (!response?.code || loginLoading.value)
    return null
  try {
    loginLoading.value = true
    googleUserRes.value = {
      code: response?.code ?? '',
      redirect_uri: getWebsiteUrl() + suffixPath,
      invite_code: icCache.get(),
    }
    const res = await googleLogin(googleUserRes.value)
    if (res)
      googleUserRes.value = void 0
    return res
  }
  catch (error: any) {
    handleResponseError(error?.code, error?.message, 'google', error?.email)
    return null
  }
  finally {
    loginLoading.value = false
  }
}
// Google登录end

onUnmounted(tronLinkTimer.pause)

defineExpose({ confirmTelegramLogin, confirmGoogleLogin })
</script>

<template>
  <div>
    <FormTitle :title="t('qFr6o3hEMqfp93rATJHw')" />

    <AForm @keypress.enter="handleLogin()">
      <AFormItem name="account" v-bind="validateInfos.account">
        <AInput
          v-model:value="formData.account"
          class="!h-[40px] !text-[14px] !p-[11px_12px]"
          :placeholder="t('aaboGsyu6pHcKErDprVK')"
          allowClear
        >
          <template #prefix>
            <i class="i-ant-design:user-outlined !text-[#266EF1] !text-[16px]" />
          </template>
        </AInput>
      </AFormItem>
      <AFormItem name="password" v-bind="validateInfos.password">
        <AInputPassword
          v-model:value="formData.password"
          class="!h-[40px] !text-[14px] !p-[11px_12px]"
          :placeholder="t('0We87UJbtA0kpmaH3h5xT')"
          allowClear
        >
          <template #prefix>
            <i class="i-ant-design:lock-outlined !text-[#266EF1] !text-[16px]" />
          </template>
        </AInputPassword>
      </AFormItem>
      <AFormItem name="captcha" v-bind="validateInfos.captcha">
        <CaptchaInput ref="captchaInputDomRef" v-model="formData.captcha" @changeId="changeCaptchaId" />
      </AFormItem>

      <AButton
        class="comfirm-btn !h-[40px] !text-[17px] w-full"
        :loading="loginLoading"
        data-test-id="5e6e"
        @click="handleLogin()"
      >
        {{ t('qFr6o3hEMqfp93rATJHw') }}
      </AButton>
      <div class="flex items-center justify-between text-[#8098BF] text-[12px] mt-[10px]">
        <div class="cursor-pointer hover:text-[#266ef1] hover:underline" data-test-id="45lq" @click="emits('changePages', 'register')">
          {{ t('hoEN8RZzz8l0qGDiZs6k') }}
        </div>
        <div class="cursor-pointer hover:text-[#266ef1] hover:underline" data-test-id="rxf5" @click="emits('changePages', 'forgetPassword')">
          {{ t('esyxvTmiaZzYwqd5cdcIm') }}
        </div>
      </div>

      <ADivider class="last-login-tips">{{ t('6Akw5J1EXUcWo3LMo9Ye') }}</ADivider>
      <div class="grid grid-cols-3 gap-x-4 mt-5">
        <div class="login-item flex items-center justify-center gap-x-1 !text-[12px] !tablet:text-[14px]" data-test-id="sxso" @click="onTronLoginBtn">
          <img :src="walletInfo.imgSrc" class="shrink-0 w-5">
          <div>{{ walletInfo.name }}</div>
        </div>

        <GoogleCheck @callback="confirmGoogleLogin">
          <template #default="{ startCheck }">
            <div
              class="login-item flex items-center justify-center gap-x-1 !text-[12px] !tablet:text-[14px]"
              data-test-id="x8qa"
              @click="startCheck"
            >
              <img src="@/assets/icons/google-logo-rounded.svg" class="shrink-0 w-5">
              <div>Google</div>
            </div>
          </template>
        </GoogleCheck>

        <TgCheck requestType="login" @callback="confirmTelegramLogin">
          <template #default="{ startCheck }">
            <div
              class="login-item flex items-center justify-center gap-x-1 !text-[12px] !tablet:text-[14px]"
              data-test-id="nlq3"
              @click="startCheck"
            >
              <img src="@/assets/icons/telegram-logo-rounded.svg" class="shrink-0 w-5">
              <div>Telegram</div>
            </div>
          </template>
        </TgCheck>
      </div>
    </AForm>
    <TronLoginConfirm v-model="showTronLoginModal" @confirm="confirmTronLinkLogin" />
  </div>
</template>

<style lang="scss" scoped>
  .last-login-tips {
    margin: 16px 0 18px !important;
    font-size: 14px !important;
    color: #8098BF !important;
    text-align: center !important;

    &::before,
    &::after {
      border-color: #8098BF !important;
    }
  }

  .login-item {
    height: 34px;
    color: #8098BF;
    cursor: pointer;
    background: #2E3F73;
    border-radius: 10px;

    &:hover{
      color: #266ef1;
    }

    &:active{
      transform: scale(0.98);
    }
  }
</style>
