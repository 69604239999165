export const appHead = {"meta":[{"charset":"utf-8"},{"name":"referrer","content":"no-referrer"},{"name":"viewport","content":"width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"}],"style":[],"script":[{"src":"/browserUpdateTips.js"},{"src":"/font/iconfont.js"},{"src":"/agentSDK.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'