import { imtoken } from '~/utils/imtoken'
import { bitpie } from '~/utils/bitpie'
import { tron } from '~/utils/tron-v2'
import walletLogo from '~/assets/icons/wallet-s3.svg'
import imtokenLogo from '~/assets/icons/im-token-logo-rounded.svg'
import bitpieLogo from '~/assets/icons/bitpie-logo-rounded.svg'
import tronLinkLogo from '~/assets/icons/tron-link-logo-rounded.svg'

const walletInfo = {
  tronLink: { imgSrc: tronLinkLogo, name: 'TronLink', iconName: 'tron-link-logo-rounded' },
  imToken: { imgSrc: imtokenLogo, name: 'ImToken', iconName: 'im-token-logo-rounded' },
  bitpie: { imgSrc: bitpieLogo, name: 'Bitpie', iconName: 'bitpie-logo-rounded' },
  default: { imgSrc: walletLogo, name: 'Wallet', iconName: 'wallet-s3' },
}

export function getWalletInfo() {
  if (!client.isMobile)
    return walletInfo.tronLink

  if (imtoken.isImToken())
    return walletInfo.imToken

  if (bitpie.isBitpie())
    return walletInfo.bitpie

  if (tron.supported())
    return walletInfo.tronLink

  return walletInfo.default
}

// 是否是网站跳转模式
export function getIsToUrlMode() {
  const isMb = client.isMobile
  return isMb || (isMb && !!tron.tronWeb) || (isMb && imtoken.isImToken())
}

export function isEdgeBrowser() {
  if (navigator.userAgent.includes('Edge')) {
    return true
  }
  else if (((navigator as any)?.userAgentData?.brands?.length ?? 0) > 0) {
    for (let i = 0; i < (navigator as any)?.userAgentData?.brands?.length; i++) {
      if ((navigator as any).userAgentData.brands[i].brand === 'Microsoft Edge')
        return true
    }
  }
  return false
}

export const getWebsiteUrl = () => `${window.location.protocol}//${window.location.hostname}`

/** 跳转TG登录检查 */
export function handleToTelegramLogin() {
  const httpUrl = encodeURIComponent(getWebsiteUrl())
  const botId = env.test ? 5613182346 : 5612411988
  const routerUrl = encodeURIComponent(useRoute().path)
  const url = `https://oauth.telegram.org/auth?bot_id=${botId}&origin=${httpUrl}&embed=1&request_access=write&return_to=${httpUrl}${routerUrl}`
  isEdgeBrowser() ? window.open(url, '_self') : (window.location.href = url)
}

/** 处理TG登录路由参数 */
export function haveTgAuthResult() {
  let locationHash = ''
  const re = /[#\?\&]tgAuthResult=([A-Za-z0-9\-_=]*)$/
  let match: string[] | null = null
  try {
    locationHash = window.location.hash.toString()
    // eslint-disable-next-line no-cond-assign
    if ((match = locationHash.match(re))) {
      window.location.hash = locationHash.replace(re, '')
      let data = match ? match[1] : ''
      data = data.replace(/-/g, '+').replace(/_/g, '/')
      const pad = data.length % 4
      if (pad > 1)
        data += Array.from({ length: 5 - pad }).join('=')
      return data ? JSON.parse(window.atob(data)) : false
    }
  }
  catch {
    return false
  }
  return false
}

/** 跳转谷歌登录检查 */
export function toGoogleAuth() {
  const routerUrl = encodeURIComponent(`${getWebsiteUrl()}/b2c`)
  const scope = encodeURIComponent('email profile')
  const client_id = encodeURIComponent(
    '995202276730-31eo0s958blcdkvdpqncjriktehrrd67.apps.googleusercontent.com',
  )
  const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=${routerUrl}&scope=${scope}&client_id=${client_id}`
  isEdgeBrowser() ? window.open(url, '_self') : (window.location.href = url)
}
