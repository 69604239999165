<script lang="ts" setup>
import Copy from '@/components/copy'
import { useUserInfo } from '~/composables/app'
import { hasToken } from '~/utils'

const { config } = useAppState()

const visible = ref(false)
const { userInfo } = useUserInfo()

function open() {
  visible.value = true
}
function close() {
  visible.value = false
}

defineExpose({ open, close })
</script>

<template>
  <AModal
    v-model:open="visible"
    wrapClassName="ant-cover__basic-modal"
    :footer="null"
    :width="500"
    centered
  >
    <div class="recommend-popup-wrapper p-[10px] mx-auto my-0">
      <img class="img block m-[0_auto] w-232px h-181px" src="@/assets/imgs/recommend-popup.png">
      <h2 class="title text-24px blod text-[#e2e8f2] text-center mt-23px mb-20px lt-mobile:text-22px">
        {{ $t("fRhkhiBmsPgXgYtV3B8Gs") }}
      </h2>
      <p class="tip text-[16px] text-center mb-20px text-[#8098bf]">
        {{
          $t('Bt7xYYFIxGWLvuDCze6bo',
             {
               order: formatRate(config?.promote.order_commission_ratio ?? 0),
               exchange: formatRate(config?.promote.exchange_commission_ratio ?? 0) },
          )
        }}
      </p>

      <div v-if="hasToken()" class="recommend-link flex justify-between items-center mt-20px pt-20px text-[16px] flex-wrap">
        <p class="w-86%">{{ $t("EhBvScIgVLLHboF_3B34h") }}：{{ userInfo?.invite_url }}</p>
        <Copy :content="userInfo?.invite_url" />
      </div>
      <AButton
        v-else class="comfirm-btn flex-center!"
        block
        :href="`/console/buyer/my-recommendation/referrals-log?lang=${$t('console-lang')}`"
        target="_blank"
      >
        {{ $t("zMjIgroyvKZhp_sxjLND6") }}
      </AButton>
    </div>
  </AModal>
</template>
