export const themes = {
  token: {
    colorPrimary: '#266ef1',
    colorBorderSecondary: 'transparent',
    borderRadius: 7,
    fontSize: 16,
  },
  components: {
    DatePicker: {
      colorBgContainer: '#13192E',
      colorBgElevated: '#202C52',
      colorText: '#E2E8F2',
      colorTextLightSolid: '#fff',
      colorTextDisabled: '#8098BF',
      colorTextHeading: '#E2E8F2',
      colorIcon: '#8098BF',
      colorIconHover: '#E2E8F2',
      colorTextDescription: '#E2E8F2',
    },
    Select: {
      fontSizeLG: 16,
      fontSize: 14,
      colorBgContainer: '#202C52',
      colorBgContainerDisabled: '#13192E',
      colorBorder: '#13192E',
      colorIcon: '#E2E8F2',
      colorIconHover: '#E2E8F2',
      colorPrimary: '#148AF5',
      colorPrimaryHover: '#148AF5',
      colorText: '#E2E8F2',
      colorTextDescription: '#8098BF',
      colorTextDisabled: '#8098BF',
      colorTextPlaceholder: '#8098BF',
      colorTextQuaternary: '#E2E8F2',
      colorTextTertiary: '#E2E8F2',
      controlItemBgActive: '#1A2342',
      colorBgElevated: '#13192E',
      controlItemBgHover: 'rgba(26, 35, 66, 1)',
      borderRadius: 6,
      borderRadiusLG: 6,
      borderRadiusSM: 6,
      borderRadiusXS: 6,
    },
    Input: {
      colorIcon: '#8098BF',
      colorIconHover: '#8098BF',
      colorPrimary: '#148AF5',
      colorPrimaryActive: '#148AF5',
      colorPrimaryHover: '#148AF5',
      colorText: '#E2E8F2',
      colorTextDescription: '#8098BF',
      colorTextDisabled: '#3A4A71',
      colorTextPlaceholder: '#8098BF',
      colorFillAlter: '#1c2440',
      colorTextQuaternary: '#8098BF',
      colorTextTertiary: '#8098BF',
      colorBgContainer: '#13192E',
      colorBgContainerDisabled: '#1A254A',
      colorBorder: '#0D0F1C',
      controlHeight: 45,
      controlHeightSM: 35,
      borderRadius: 10,
      borderRadiusSM: 10,
      // fontSizeLG: 18,
      // fontSizeXS: 14,
      // fontSize: 16,
      // fontSizeIcon: 16,

      lineHeight: 1,
      // paddingSM: 14,
      // paddingXS: 9,
    },
    InputNumber: {
      colorIcon: '#8098BF',
      colorIconHover: '#8098BF',
      colorPrimary: '#148AF5',
      colorPrimaryActive: '#148AF5',
      colorPrimaryHover: '#148AF5',
      colorText: '#E2E8F2',
      colorTextDescription: '#8098BF',
      colorTextDisabled: '#3A4A71',
      colorTextPlaceholder: '#8098BF',
      colorBgContainer: '#13192E',
      colorBgContainerDisabled: '#1A254A',
      colorBorder: '#0D0F1C',
      controlHeight: 50,
      controlHeightSM: 40,
      borderRadius: 10,
      borderRadiusSM: 10,
      // fontSizeLG: 18,
      // fontSizeXS: 14,
      // fontSize: 16,
      // fontSizeIcon: 22,
      lineHeight: 1,
      // paddingSM: 14,
      // paddingXS: 9,
    },
    Form: {
      controlHeight: 40,
      colorText: '#E2E8F2',
      colorTextHeading: '#E2E8F2',
      colorBorder: '#13192E',
      fontSizeLG: 18,
      fontSize: 16,
      margin: 16,
    },
    Table: {
      borderRadius: 0,
      borderRadiusLG: 0,
      colorBgContainer: '#1a2342',
      colorText: '#e2e8f2',
      colorTextHeading: '#8098bf',
      colorBorderSecondary: 'none',
      fontSize: 16,
      colorFillAlter: '#222c52',

    },
    Spin: {
      colorBgContainer: '#13192E',
    },
    Modal: {
      colorSuccess: '#3AB55B',
      colorText: '#333C48',
      colorIconHover: '#69727D',
    },
    Pagination: {
      colorPrimaryBorder: '#24325C',
      colorPrimary: '#E2E8F2',
      colorPrimaryHover: '#E2E8F2',
      colorText: '#8098BF',
      colorTextDisabled: '#000000',
      colorBorder: '#24325C',
      colorBgContainer: '#24325C',
      colorBgContainerDisabled: '#24325C',
      colorBgTextActive: '#E2E8F2',
      colorBgTextHover: '#266EF1',
      controlItemBgActiveDisabled: '#266EF1',
      controlOutline: '#266EF1',
      controlPaddingHorizontal: 9,
      paddingSM: 9,
      wireframe: false,
    },
  },
}
