export type CaptchaGetType = Api.GetData<typeof apis.captchaGet>

export function useCaptcha(immediate = true, change?: (v: CaptchaGetType | null) => void) {
  const captchaRef = ref<CaptchaGetType | null>()
  const loading = ref(false)
  async function getCaptchaFn() {
    loading.value = true
    try {
      captchaRef.value = await apis.captchaGet({ type: '' })
      change?.(captchaRef.value)
    }
    finally {
      loading.value = false
    }
  }
  immediate && getCaptchaFn()
  return { getCaptchaFn, captchaRef, loading }
}
