import { reactiveComputed } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { TronRes, tron } from '~/utils/tron-v2'
import { sleep } from '~/utils'

export { useTronAccount }

function useTronAccount(address: Ref<string>, chainId: Ref<string>) {
  const enabled = computed(() => !!unref(address) && !!unref(chainId))
  const accountKey = computed(() => unref(address) + unref(chainId))
  const infoQuerier = useQuery({
    enabled,
    queryKey: ['tron-info', accountKey],
    refetchOnWindowFocus: false,
    staleTime: 1000,
    queryFn: async () => {
      const res = await tron.fetchAccount(unref(address))
      return res ?? null
    },
  })

  const resQuerier = useQuery({
    enabled,
    queryKey: ['tron-res', accountKey],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await tron.fetchAccountResource(unref(address))
      return res ?? null
    },
  })

  const delegableQuerier = useQuery({
    enabled: computed(() => {
      const route = useRoute()
      const routeName = String(route.name)
      const allowNames = ['c2c___', 'b2c___']
      return allowNames.some(name => routeName.startsWith(name)) && !!unref(infoQuerier.data)
    }),
    queryKey: ['tron-delegable', accountKey],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const _address = unref(address)
      const { frozenV2 } = unref(infoQuerier.data)

      let energy: number | undefined = 0
      let bandwidth: number | undefined = 0

      if (!frozenV2)
        return { energy, bandwidth }

      if (toValue(frozenV2)[1]?.amount)
        energy = await tron.fetchCanDelegatedMaxSize(_address, TronRes.ENERGY)

      if (toValue(frozenV2)[0]?.amount)
        bandwidth = await tron.fetchCanDelegatedMaxSize(_address, TronRes.BANDWIDTH)

      return { energy, bandwidth }
    },
  })

  const extractQuerier = useQuery({
    enabled,
    queryKey: ['extract-querier', accountKey],
    queryFn: async () => {
      const _address = unref(address)
      return await tron.fetchCanwithdrawunfreezeamount(_address)
    },
  })

  // 获取的数据有较大延迟，即时数据使用用户信息内的
  const balanceQuerier = useQuery({
    enabled,
    refetchOnMount: false,
    queryKey: ['account-balance', accountKey],
    queryFn: async () => {
      const _address = unref(address)
      const _chainId = unref(chainId)
      const balance = { trx: 0, usdt: 0 }

      if (!_address || !_chainId)
        return balance

      // 避免过多的瞬时请求
      !balanceQuerier.isFetched.value && await sleep(2000)
      balance.trx = (await tron.fetchBalance(_address, _chainId).catch(() => {})) ?? 0
      balance.usdt = (await tron.fetchBalance(_address, _chainId, 'USDT').catch(() => {})) ?? 0

      return balance
    },
  })

  const account = reactiveComputed(() => {
    const info = unref(infoQuerier.data)
    const resource = unref(resQuerier.data)

    const delegable = unref(delegableQuerier.data)
    const balance = unref(balanceQuerier.data)
    const extract = unref(extractQuerier.data)
    const energyUnitPrice = resource?.TotalEnergyWeight
      ? (1 / (resource?.TotalEnergyWeight ?? 0)) * (resource?.TotalEnergyLimit ?? 0)
      : 0
    const netUnitPrice = resource?.TotalNetWeight ? (1 / resource.TotalNetWeight) * (resource?.TotalNetLimit ?? 0) : 0

    return {
      ...info,
      /** TRX 余额（低延迟） */
      balanceTrx: info?.balance,
      chainId: unref(chainId),
      address: unref(address),
      /** 账户余额（延迟较高） */
      balance,
      resource: {
        ...resource,
        /** 能量单价（本地计算）  */
        energyUnitPrice,
        /** 宽带单价（本地计算）  */
        netUnitPrice,
      },
      delegable,
      extract,
    }
  })

  const refetch = async () => {
    await Promise.all([resQuerier.refetch(), infoQuerier.refetch(), resQuerier.refetch(), delegableQuerier.refetch(), extractQuerier.refetch(), balanceQuerier.refetch()])
  }

  return { account: readonly(account), refetch, balanceQuerier }
}
