<script setup lang="ts">
import { Modal as AModal } from 'ant-design-vue'

const visible = useBoolean()

defineExpose({
  open: visible.setTrue,
  close: visible.setFalse,
})
</script>

<template>
  <AModal
    v-model:open="visible.is"
    :footer="null"
    :width="450"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
  >
    <div class="flex justify-center items-center flex-col gap-[30px]">
      <i class="i-local:warning text-[60px]" />
      <p>{{ $t('nBr2Lv94yZeV5Q2HIhtBr') }}</p>
      <AButton class="comfirm-btn min-w-[160px]" @click="visible.setFalse">
        {{ $t('7sf5sL2XdyefcbfuW6666') }}
      </AButton>
    </div>
  </AModal>
</template>
