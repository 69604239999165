import { computed, ref } from 'vue'
import { createGlobalState } from '@vueuse/core'

export const useUserState = createGlobalState(
  () => {
    // state
    const userKey = ref('')

    // getters
    const getUserKey = computed(() => userKey)

    // actions
    function editUserKey(key: string) {
      userKey.value = key
    }

    return { getUserKey, editUserKey }
  },
)
