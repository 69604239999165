<script setup lang="ts">
import { Modal as AModal } from 'ant-design-vue'
import { tron } from '~/utils/tron-v2'

const visible = useBoolean()

async function replace() {
  tron.switchChain().then((e) => {
    if (e === null)
      window.location.reload()
  })
}

defineExpose({
  open: visible.setTrue,
  close: visible.setFalse,
})
</script>

<template>
  <AModal
    v-model:open="visible.is"
    :footer="null"
    :width="490"
    destroyOnClose
    :closable="false"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
    :zIndex="9999"
    @cancel="visible.setFalse()"
  >
    <div class="flex-center flex-col gap-30px">
      <i class="i-local:warning text-60px" />
      <p>
        {{ $t('O0LxkVO8y5jJXbmwRqBgN') }}
      </p>
      <AButton class="comfirm-btn min-h-50px min-w-170px" @click="replace" data-test-id="dz8w">
        {{ $t('Az9Qs3_RdA_qQ6jmId5Tl') }}
      </AButton>
    </div>
  </AModal>
</template>
